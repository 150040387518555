import React from 'react';
import {
  H1, H3, P, UL, LI, Flex, Row,
} from '@galilee/lilee';
import styled from 'styled-components/macro';

const Inner = styled(Row)`
  max-width: 750px; 
`;

const Privacy = () => (
  <Flex alignItems="center" flexDirection="column" justifyContent="center" p={7} width="100%">
    <Inner alignItems="center">
      <Flex flexDirection="column" maxWidth="700px" mx="auto" mb="7">
        <H1 fontSize={[5, null, null, 6]} mt="4" mb="6">Privacy Policy</H1>
        <H3 my="6">Our commitment to protect your privacy</H3>
        <P>
          thelawstore.com.au understands how important it is to protect your personal information.
          This document sets out our privacy policy commitment in respect of personal information
          we hold about you and what we do with that information.
        </P>
        <P>
          We recognise that any personal information we collect about you should only be used for
          the purposes we have collected it for or as allowed under the law. It is important to us
          that you are confident that any personal information we hold about you will be treated
          in a way which ensures protection of your personal information.
        </P>
        <P>
          Our commitment in respect of personal information is to abide by the Australian Privacy
          Principles for the protection of personal information, as set out in the Privacy Act and
          any other relevant law.
        </P>
        <H3 my="6">Personal information</H3>
        <P>
          When we refer to personal information we mean information from which your identity is
          reasonably apparent. This information may include information or an opinion about you.
        </P>
        <H3 my="6">What information do we collect?</H3>
        <P>
          We do not collect personal information about you unless the information is reasonably
          necessary for providing legal services or products to you or relating to you, or is
          directly related to the provision of these services. The kinds of personal information
          we may collect about you include transaction information, your name, date of birth,
          address, occupation and any other information we may need to identify you.
        </P>
        <H3 my="6">Collection of Biometric Information</H3>
        <P>
          Livesign will ask you to take a photo of yourself during the Verification of Identity
          process. This is so that we can use biometric methods to compare your image against the
          image on your identity document(s).
        </P>
        <H3 my="6">Why we collect your personal information</H3>
        <P>
          We collect personal information for the purposes of allowing legal representatives to
          undertake a verification of identity transaction, as required by The Electronic Conveyancing
          National Law (https://www.arnecc.gov.au/regulation/electronic_conveyancing_national_law/).
          Personal information is only accessible by registered users of the legal representative,
          however we may from time to time be required to access personal information for the
          purposes of assisting in the completion of a transaction or other customer support
          activities. We may also collect your personal information for the purposes of direct
          marketing and managing our relationship with you. From time to time we may offer you
          other products and services.
        </P>
        <H3 my="6">Consequences if personal information is not collected</H3>
        <P>
          If you do not consent to the collection of your personal information you will be unable to
          complete the verification of identity transaction, and will need to make alternative
          arrangements with the organisation that wants to verify your identity.
        </P>
        <H3 my="6">How do we collect your personal information?</H3>
        <P>
          Where reasonable and practical we will collect your personal information directly from you.
          Other parties, such as Legal Representatives and other law firms, might also give us
          personal information about you.
        </P>
        <H3 my="6">How do we hold your personal information?</H3>
        <P>
          The information we hold about you will be stored either electronically in secure data centres
          located in Australia or on physical files which are managed in secure business premises
          accessible only to our staff. We are also required to retain certain records for up to
          7 years and where we do so we utilise contracted service providers who also implement
          information security controls to ensure your information is protected. We use a range of
          physical, electronic and other security measures to protect the security,
          confidentiality and integrity of the personal information we hold.
        </P>
        <H3 my="6">Overseas recipients</H3>
        <P>
          We will not disclose personal information to overseas recipients, except in the circumstances
          where the information on your passport may be disclosed to Inverid (https://www.inverid.com/)
          in Northern Ireland for the purposes of verifying your passport credentials using
          International Civil Aviation Organisation Standard 9303.
        </P>
        <H3 my="6">Do we disclose your personal information?</H3>
        <P>
          We will only use your personal information for the purpose for which it was collected and
          for any directly related purpose. We may disclose your personal information:
        </P>
        <UL fontSize={2}>
          <LI>
            to anybody who represents you;
          </LI>
          <LI>
            to anyone, where you have provided us consent;
          </LI>
          <LI>
            to anyone, where disclosure to that party is for the purpose for which the information
            was collected or directly related to that purpose;
          </LI>
          <LI>
            where we are required to do so by law, such as under the Anti-Money or Laundering and
            Counter Terrorism Financing Act 2006 (Cth); or
          </LI>
          <LI>
            to organisations that provide products or services used by us.
          </LI>
        </UL>
        <P>
          Prior to disclosing any of your personal information to another person or organisation,
          we will take all reasonable steps to satisfy ourselves that:
        </P>
        <P>
          (a) the person or organisation has a commitment to protecting your personal information
          at least equal to our commitment, or
        </P>
        <P>(b) you have consented to us making the disclosure.</P>
        <H3 my="6">Direct marketing</H3>
        <P>
          From time to time we may use your personal information to provide you with current
          information about offers you may find of interest, changes to our organisation, or
          new products or services being offered by us or any company with whom we are associated.
        </P>
        <P>
          If we market products and services to you, we will comply with applicable Privacy
          Laws to obtain your consent if required.
        </P>
        <P>
          If you do not wish to receive marketing information, you may at any time decline to receive
          such information by contacting us via support@live-sign.com. We will not charge you for
          giving effect to your request and will take all reasonable steps to meet your request at
          the earliest possible opportunity.
        </P>
        <H3 my="6">Updating your personal information</H3>
        <P>
          It is important to us that the personal information we hold about you is accurate and up to date.
          During the course of our relationship with you we may ask you to inform us if any of your
          personal information has changed.
        </P>
        <P>
          If you wish to make any changes to your personal information, you may contact us. We will generally
          rely on you to ensure the information we hold about you is accurate or complete.
        </P>
        <H3 my="6">Access and correction to your personal and credit information</H3>
        <P>
          We will provide you with access to the personal information we hold about you. You may request
          access to any of the personal information we hold about you at any time. We may charge a fee
          for our costs of retrieving and supplying the information to you.
        </P>
        <P>
          Depending on the type of request that you make we may respond to your request immediately, otherwise
          we usually respond to you within seven days of receiving your request. We may need to contact other
          entities to properly investigate your request.
        </P>
        <P>
          There may be situations where we are not required to provide you with access to your personal
          information, for example, if the information relates to existing or anticipated legal proceedings.
          An explanation will be provided to you, if we deny you access to the personal or credit information
          we hold about you.
        </P>
        <P>
          If any of the personal information we hold about you is incorrect, inaccurate or out of date you
          may request that we correct the information. If appropriate we will correct the personal information
          at the time of the request, otherwise, we will provide an initial response to you within seven days
          of receiving your request. Where reasonable, and after our investigation, we will provide you with
          details about whether we have corrected the personal information within 30 days.
        </P>
        <H3 my="6">Using government identifiers</H3>
        <P>
          If we collect government identifiers, such as your tax file number, we do not use or disclose this
          information other than required by law. We will never use a government identifier in order to identify
          you.
        </P>
        <H3 my="6">How safe and secure is your personal information that we hold?</H3>
        <P>
          We will take reasonable steps to protect your personal information by storing it in a secure
          environment. We may store your personal information in paper and electronic form. We will also take
          reasonable steps to protect any personal information from misuse, loss and unauthorised access,
          modification or disclosure.
        </P>
        <H3 my="6">Notifiable Data Breaches</H3>
        <P>
          Effective from 22 February 2018, the Privacy Act includes a Notifiable Data Breaches scheme which
          requires us to notify you and the Office of the Australian Information Commissioner (OAIC) of
          certain data breaches and recommend steps you can take to limit the impacts of a breach.
        </P>
        <P>
          The Notifiable Data Breaches scheme requires us to notify about a data breach that is likely to
          result in serious harm to affected individuals. There are exceptions where notification is not
          required. For example, where we have already taken appropriate remedial action that removes the
          risk of serious harm to any individuals.
        </P>
        <P>
          If we believe there has been a data breach that impacts your personal information and creates a
          likely risk of serious harm, we will notify you and the OAIC as soon as practicable and keep in
          close contact with you about the nature of the breach, the steps we are taking and what you can
          do to reduce the impacts to your privacy.
        </P>
        <P>
          If you believe that any personal information we hold about you has been impacted by a data breach,
          you can contact us using the contact details below.
        </P>
        <H3 my="6">Complaints – your rights</H3>
        <P>
          If you are dissatisfied with how we have dealt with your personal information, or you have a
          complaint about our compliance with the Privacy Act and the Credit Reporting Code, you may
          contact us at support@live-sign.com.
        </P>
        <P>
          We will acknowledge your complaint within seven days. We will provide you with a decision on
          your complaint within 30 days.
        </P>
        <P>
          If you are dissatisfied with our response you may make a complaint to the OAIC which can be
          contacted on either www.oaic.gov.au or 1300 363 992.
        </P>
        <H3 my="6">Further information</H3>
        <P>
          You may request further information about the way we manage your personal information by
          contacting us.
        </P>
        <H3 my="6">Change in our privacy policy</H3>
        <P>
          We are continuously reviewing our policies to keep up to date with changes in the law,
          technology and industry practice.
        </P>
        <P>
          As a consequence we may change this privacy policy from time to time or as the
          need arises.
        </P>
        <P>
          You may request an up-to-date version of our privacy policy at any time.
        </P>
        <H3 my="6">About us</H3>
        <P>
          thelawstore.com.au PTY LTD
        </P>
        <P>
          ACN: 653 541 729
        </P>
        <P>
          Address: Level 16, 60 Carrington Street, Sydney NSW 2000.
        </P>
        <P>
          Contact: support@live-sign.com
        </P>
      </Flex>
    </Inner>
  </Flex>
);

export default Privacy;
