/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Text } from '@galilee/lilee';
import React from 'react';

const Footer = () => (
  <Box py="7" m="2">
    <Text fontSize={1}>
      Livesign is part of thelawstore.com.au, a firm of legal, digital and
      process specialists, assisting our clients to innovate and disrupt.
    </Text>
    <Text fontSize={0}>
      <span>&#169;</span>
      2021 thelawstore.com.au
    </Text>
  </Box>
);

export default Footer;
