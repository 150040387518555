import { Button } from '@galilee/lilee';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const StyledButton = styled(Button)`
  background-color: inherit;
  border: 0;
  color: ${(p) => p.theme.colors[p.color]};
  font-size: ${(p) => (typeof p.fontSize === 'string'
    ? p.fontSize
    : p.theme.fontSizes[p.fontSize])};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  min-width: 0;
  letter-spacing: 0.2px;
  line-height: 120%;
  outline: 0;
  padding: 0;
  text-transform: ${(p) => p.textTransform};
  max-width: 130px; 

  ${(p) => p.disableHover
    && `
  &:hover {
    background-color: unset;
    padding: unset;
  }

  & > div {
    padding: 0;
  }`}
`;

const CancelButton = ({
  children,
  color,
  disableHover,
  fontSize,
  onClick,
  textTransform,
}) => (
  <StyledButton
    color={color}
    disableHover={disableHover}
    fontSize={fontSize}
    onClick={onClick}
    textTransform={textTransform}
  >
    {children}
  </StyledButton>
);

CancelButton.defaultProps = {
  children: null,
  color: 'secondary',
  disableHover: false,
  fontSize: null,
  onClick: null,
  textTransform: 'none',
};
CancelButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  color: PropTypes.string,
  disableHover: PropTypes.bool,
  fontSize: PropTypes.string,
  onClick: PropTypes.func,
  textTransform: PropTypes.string,
};

export default CancelButton;
