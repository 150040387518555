const countryOptions = [
  {
    id: 1,
    name: 'Afghanistan ',
    countryCode: 'AF',
    phoneCode: '+93',
  },
  {
    id: 2,
    name: 'Albania ',
    countryCode: 'AL',
    phoneCode: '+355',
  },
  {
    id: 3,
    name: 'Algeria ',
    countryCode: 'DZ',
    phoneCode: '+213',
  },
  {
    id: 4,
    name: 'American Samoa',
    countryCode: 'AS',
    phoneCode: '+1-684',
  },
  {
    id: 5,
    name: 'Andorra, Principality of ',
    countryCode: 'AD',
    phoneCode: '+376',
  },
  {
    id: 6,
    name: 'Anla',
    countryCode: 'AO',
    phoneCode: '+244',
  },
  {
    id: 7,
    name: 'Anguilla ',
    countryCode: 'AI',
    phoneCode: '+1-264',
  },
  {
    id: 8,
    name: 'Antarctica',
    countryCode: 'AQ',
    phoneCode: '+672',
  },
  {
    id: 9,
    name: 'Antigua and Barbuda',
    countryCode: 'AG',
    phoneCode: '+1-268',
  },
  {
    id: 10,
    name: 'Argentina ',
    countryCode: 'AR',
    phoneCode: '+54',
  },
  {
    id: 11,
    name: 'Armenia',
    countryCode: 'AM',
    phoneCode: '+374',
  },
  {
    id: 12,
    name: 'Aruba',
    countryCode: 'AW',
    phoneCode: '+297',
  },
  {
    id: 13,
    name: 'Australia',
    countryCode: 'AU',
    phoneCode: '+61',
  },
  {
    id: 14,
    name: 'Austria',
    countryCode: 'AT',
    phoneCode: '+43',
  },
  {
    id: 15,
    name: 'Azerbaijan or Azerbaidjan (Former Azerbaijan Soviet Socialist Republic)',
    countryCode: 'AZ',
    phoneCode: '+994',
  },
  {
    id: 16,
    name: 'Bahamas, Commonwealth of The',
    countryCode: 'BS',
    phoneCode: '+1-242',
  },
  {
    id: 17,
    name: 'Bahrain, Kingdom of (Former Dilmun)',
    countryCode: 'BH',
    phoneCode: '+973',
  },
  {
    id: 18,
    name: 'Bangladesh (Former East Pakistan)',
    countryCode: 'BD',
    phoneCode: '+880',
  },
  {
    id: 19,
    name: 'Barbados ',
    countryCode: 'BB',
    phoneCode: '+1-246',
  },
  {
    id: 20,
    name: 'Belarus (Former Belorussian [Byelorussian] Soviet Socialist Republic)',
    countryCode: 'BY',
    phoneCode: '+375',
  },
  {
    id: 21,
    name: 'Belgium ',
    countryCode: 'BE',
    phoneCode: '+32',
  },
  {
    id: 22,
    name: 'Belize (Former British Honduras)',
    countryCode: 'BZ',
    phoneCode: '+501',
  },
  {
    id: 23,
    name: 'Benin (Former Dahomey)',
    countryCode: 'BJ',
    phoneCode: '+229',
  },
  {
    id: 24,
    name: 'Bermuda ',
    countryCode: 'BM',
    phoneCode: '+1-441',
  },
  {
    id: 25,
    name: 'Bhutan, Kingdom of',
    countryCode: 'BT',
    phoneCode: '+975',
  },
  {
    id: 26,
    name: 'Bolivia ',
    countryCode: 'BO',
    phoneCode: '+591',
  },
  {
    id: 27,
    name: 'Bosnia and Herzevina ',
    countryCode: 'BA',
    phoneCode: '+387',
  },
  {
    id: 28,
    name: 'Botswana (Former Bechuanaland)',
    countryCode: 'BW',
    phoneCode: '+267',
  },
  {
    id: 29,
    name: 'Brazil ',
    countryCode: 'BR',
    phoneCode: '+55',
  },
  {
    id: 30,
    name: 'Brunei (Negara Brunei Darussalam) ',
    countryCode: 'BN',
    phoneCode: '+673',
  },
  {
    id: 31,
    name: 'Bulgaria ',
    countryCode: 'BG',
    phoneCode: '+359',
  },
  {
    id: 32,
    name: 'Burkina Faso (Former Upper Volta)',
    countryCode: 'BF',
    phoneCode: '+226',
  },
  {
    id: 33,
    name: 'Burundi (Former Urundi)',
    countryCode: 'BI',
    phoneCode: '+257',
  },
  {
    id: 34,
    name: 'Cambodia, Kingdom of (Former Khmer Republic, Kampuchea Republic)',
    countryCode: 'KH',
    phoneCode: '+855',
  },
  {
    id: 35,
    name: 'Cameroon (Former French Cameroon)',
    countryCode: 'CM',
    phoneCode: '+237',
  },
  {
    id: 36,
    name: 'Canada ',
    countryCode: 'CA',
    phoneCode: '+1',
  },
  {
    id: 37,
    name: 'Cape Verde ',
    countryCode: 'CV',
    phoneCode: '+238',
  },
  {
    id: 38,
    name: 'Cayman Islands ',
    countryCode: 'KY',
    phoneCode: '+1-345',
  },
  {
    id: 39,
    name: 'Central African Republic ',
    countryCode: 'CF',
    phoneCode: '+236',
  },
  {
    id: 40,
    name: 'Chad ',
    countryCode: 'TD',
    phoneCode: '+235',
  },
  {
    id: 41,
    name: 'Chile ',
    countryCode: 'CL',
    phoneCode: '+56',
  },
  {
    id: 42,
    name: 'China ',
    countryCode: 'CN',
    phoneCode: '+86',
  },
  {
    id: 43,
    name: 'Christmas Island ',
    countryCode: 'CX',
    phoneCode: '+53',
  },
  {
    id: 44,
    name: 'Cocos (Keeling) Islands ',
    countryCode: 'CC',
    phoneCode: '+61',
  },
  {
    id: 45,
    name: 'Colombia ',
    countryCode: 'CO',
    phoneCode: '+57',
  },
  {
    id: 46,
    name: 'Comoros, Union of the ',
    countryCode: 'KM',
    phoneCode: '+269',
  },
  {
    id: 47,
    name: 'Con, Democratic Republic of the (Former Zaire) ',
    countryCode: 'CD',
    phoneCode: '+243',
  },
  {
    id: 48,
    name: 'Con, Republic of the',
    countryCode: 'CG',
    phoneCode: '+242',
  },
  {
    id: 49,
    name: 'Cook Islands (Former Harvey Islands)',
    countryCode: 'CK',
    phoneCode: '+682',
  },
  {
    id: 50,
    name: 'Costa Rica ',
    countryCode: 'CR',
    phoneCode: '+506',
  },
  {
    id: 51,
    name: "Cote D'Ivoire (Former Ivory Coast) ",
    countryCode: 'CI',
    phoneCode: '+225',
  },
  {
    id: 52,
    name: 'Croatia (Hrvatska) ',
    countryCode: 'HR',
    phoneCode: '+385',
  },
  {
    id: 53,
    name: 'Cuba ',
    countryCode: 'CU',
    phoneCode: '+53',
  },
  {
    id: 54,
    name: 'Cyprus ',
    countryCode: 'CY',
    phoneCode: '+357',
  },
  {
    id: 55,
    name: 'Czech Republic',
    countryCode: 'CZ',
    phoneCode: '+420',
  },
  {
    id: 56,
    name: 'Denmark ',
    countryCode: 'DK',
    phoneCode: '+45',
  },
  {
    id: 57,
    name: 'Djibouti (Former French Territory of the Afars and Issas, French Somaliland)',
    countryCode: 'DJ',
    phoneCode: '+253',
  },
  {
    id: 58,
    name: 'Dominica ',
    countryCode: 'DM',
    phoneCode: '+1-767',
  },
  {
    id: 59,
    name: 'Dominican Republic ',
    countryCode: 'DO',
    phoneCode: '+1-809 and +1-829  ',
  },
  {
    id: 60,
    name: 'East Timor (Former Portuguese Timor)',
    countryCode: 'TP',
    phoneCode: '+670',
  },
  {
    id: 61,
    name: 'Ecuador ',
    countryCode: 'EC',
    phoneCode: '+593 ',
  },
  {
    id: 62,
    name: 'Egypt (Former United Arab Republic - with Syria)',
    countryCode: 'EG',
    phoneCode: '+20',
  },
  {
    id: 63,
    name: 'El Salvador ',
    countryCode: 'SV',
    phoneCode: '+503',
  },
  {
    id: 64,
    name: 'Equatorial Guinea (Former Spanish Guinea)',
    countryCode: 'GQ',
    phoneCode: '+240',
  },
  {
    id: 65,
    name: 'Eritrea (Former Eritrea Autonomous Region in Ethiopia)',
    countryCode: 'ER',
    phoneCode: '+291',
  },
  {
    id: 66,
    name: 'Estonia (Former Estonian Soviet Socialist Republic)',
    countryCode: 'EE',
    phoneCode: '+372',
  },
  {
    id: 67,
    name: 'Ethiopia (Former Abyssinia, Italian East Africa)',
    countryCode: 'ET',
    phoneCode: '+251',
  },
  {
    id: 68,
    name: 'Falkland Islands (Islas Malvinas) ',
    countryCode: 'FK',
    phoneCode: '+500',
  },
  {
    id: 69,
    name: 'Faroe Islands ',
    countryCode: 'FO',
    phoneCode: '+298',
  },
  {
    id: 70,
    name: 'Fiji ',
    countryCode: 'FJ',
    phoneCode: '+679',
  },
  {
    id: 71,
    name: 'Finland ',
    countryCode: 'FI',
    phoneCode: '+358',
  },
  {
    id: 72,
    name: 'France ',
    countryCode: 'FR',
    phoneCode: '+33',
  },
  {
    id: 73,
    name: 'French Guiana or French Guyana ',
    countryCode: 'GF',
    phoneCode: '+594',
  },
  {
    id: 74,
    name: 'French Polynesia (Former French Colony of Oceania)',
    countryCode: 'PF',
    phoneCode: '+689',
  },
  {
    id: 75,
    name: 'Gabon (Gabonese Republic)',
    countryCode: 'GA',
    phoneCode: '+241',
  },
  {
    id: 76,
    name: 'Gambia, The ',
    countryCode: 'GM',
    phoneCode: '+220',
  },
  {
    id: 77,
    name: 'Georgia (Former Georgian Soviet Socialist Republic)',
    countryCode: 'GE',
    phoneCode: '+995',
  },
  {
    id: 78,
    name: 'Germany ',
    countryCode: 'DE',
    phoneCode: '+49',
  },
  {
    id: 79,
    name: 'Ghana (Former ld Coast)',
    countryCode: 'GH',
    phoneCode: '+233',
  },
  {
    id: 80,
    name: 'Gibraltar ',
    countryCode: 'GI',
    phoneCode: '+350',
  },
  {
    id: 81,
    name: 'Greece ',
    countryCode: 'GR',
    phoneCode: '+30',
  },
  {
    id: 82,
    name: 'Greenland ',
    countryCode: 'GL',
    phoneCode: '+299',
  },
  {
    id: 83,
    name: 'Grenada ',
    countryCode: 'GD',
    phoneCode: '+1-473',
  },
  {
    id: 84,
    name: 'Guadeloupe',
    countryCode: 'GP',
    phoneCode: '+590',
  },
  {
    id: 85,
    name: 'Guam',
    countryCode: 'GU',
    phoneCode: '+1-671',
  },
  {
    id: 86,
    name: 'Guatemala ',
    countryCode: 'GT',
    phoneCode: '+502',
  },
  {
    id: 87,
    name: 'Guinea (Former French Guinea)',
    countryCode: 'GN',
    phoneCode: '+224',
  },
  {
    id: 88,
    name: 'Guinea-Bissau (Former Portuguese Guinea)',
    countryCode: 'GW',
    phoneCode: '+245',
  },
  {
    id: 89,
    name: 'Guyana (Former British Guiana)',
    countryCode: 'GY',
    phoneCode: '+592',
  },
  {
    id: 90,
    name: 'Haiti ',
    countryCode: 'HT',
    phoneCode: '+509',
  },
  {
    id: 91,
    name: 'Honduras ',
    countryCode: 'HN',
    phoneCode: '+504',
  },
  {
    id: 92,
    name: 'Hong Kong ',
    countryCode: 'HK',
    phoneCode: '+852',
  },
  {
    id: 93,
    name: 'Hungary ',
    countryCode: 'HU',
    phoneCode: '+36',
  },
  {
    id: 94,
    name: 'Iceland ',
    countryCode: 'IS',
    phoneCode: '+354',
  },
  {
    id: 95,
    name: 'India ',
    countryCode: 'IN',
    phoneCode: '+91',
  },
  {
    id: 96,
    name: 'Indonesia (Former Netherlands East Indies; Dutch East Indies)',
    countryCode: 'ID',
    phoneCode: '+62',
  },
  {
    id: 97,
    name: 'Iran, Islamic Republic of',
    countryCode: 'IR',
    phoneCode: '+98',
  },
  {
    id: 98,
    name: 'Iraq ',
    countryCode: 'IQ',
    phoneCode: '+964',
  },
  {
    id: 99,
    name: 'Ireland ',
    countryCode: 'IE',
    phoneCode: '+353',
  },
  {
    id: 100,
    name: 'Israel ',
    countryCode: 'IL',
    phoneCode: '+972',
  },
  {
    id: 101,
    name: 'Italy ',
    countryCode: 'IT',
    phoneCode: '+39',
  },
  {
    id: 102,
    name: 'Jamaica ',
    countryCode: 'JM',
    phoneCode: '+1-876',
  },
  {
    id: 103,
    name: 'Japan ',
    countryCode: 'JP',
    phoneCode: '+81',
  },
  {
    id: 104,
    name: 'Jordan (Former Transjordan)',
    countryCode: 'JO',
    phoneCode: '+962',
  },
  {
    id: 105,
    name: 'Kazakstan or Kazakhstan (Former Kazakh Soviet Socialist Republic)',
    countryCode: 'KZ',
    phoneCode: '+7',
  },
  {
    id: 106,
    name: 'Kenya (Former British East Africa)',
    countryCode: 'KE',
    phoneCode: '+254',
  },
  {
    id: 107,
    name: 'Kiribati (Pronounced keer-ree-bahss) (Former Gilbert Islands)',
    countryCode: 'KI',
    phoneCode: '+686',
  },
  {
    id: 108,
    name: "Korea, Democratic People's Republic of (North Korea)",
    countryCode: 'KP',
    phoneCode: '+850',
  },
  {
    id: 109,
    name: 'Korea, Republic of (South Korea) ',
    countryCode: 'KR',
    phoneCode: '+82',
  },
  {
    id: 110,
    name: 'Kuwait ',
    countryCode: 'KW',
    phoneCode: '+965',
  },
  {
    id: 111,
    name: 'Kyrgyzstan (Kyrgyz Republic) (Former Kirghiz Soviet Socialist Republic)',
    countryCode: 'KG',
    phoneCode: '+996',
  },
  {
    id: 112,
    name: "Lao People's Democratic Republic (Laos)",
    countryCode: 'LA',
    phoneCode: '+856',
  },
  {
    id: 113,
    name: 'Latvia (Former Latvian Soviet Socialist Republic)',
    countryCode: 'LV',
    phoneCode: '+371',
  },
  {
    id: 114,
    name: 'Lebanon ',
    countryCode: 'LB',
    phoneCode: '+961',
  },
  {
    id: 115,
    name: 'Lesotho (Former Basutoland)',
    countryCode: 'LS',
    phoneCode: '+266',
  },
  {
    id: 116,
    name: 'Liberia ',
    countryCode: 'LR',
    phoneCode: '+231',
  },
  {
    id: 117,
    name: 'Libya (Libyan Arab Jamahiriya)',
    countryCode: 'LY',
    phoneCode: '+218',
  },
  {
    id: 118,
    name: 'Liechtenstein ',
    countryCode: 'LI',
    phoneCode: '+423',
  },
  {
    id: 119,
    name: 'Lithuania (Former Lithuanian Soviet Socialist Republic)',
    countryCode: 'LT',
    phoneCode: '+370',
  },
  {
    id: 120,
    name: 'Luxembourg ',
    countryCode: 'LU',
    phoneCode: '+352',
  },
  {
    id: 121,
    name: 'Macau ',
    countryCode: 'MO',
    phoneCode: '+853',
  },
  {
    id: 122,
    name: 'Macedonia, The Former Yuslav Republic of',
    countryCode: 'MK',
    phoneCode: '+389',
  },
  {
    id: 123,
    name: 'Madagascar (Former Malagasy Republic)',
    countryCode: 'MG',
    phoneCode: '+261',
  },
  {
    id: 124,
    name: 'Malawi (Former British Central African Protectorate, Nyasaland)',
    countryCode: 'MW',
    phoneCode: '+265',
  },
  {
    id: 125,
    name: 'Malaysia ',
    countryCode: 'MY',
    phoneCode: '+60',
  },
  {
    id: 126,
    name: 'Maldives ',
    countryCode: 'MV',
    phoneCode: '+960',
  },
  {
    id: 127,
    name: 'Mali (Former French Sudan and Sudanese Republic) ',
    countryCode: 'ML',
    phoneCode: '+223',
  },
  {
    id: 128,
    name: 'Malta ',
    countryCode: 'MT',
    phoneCode: '+356',
  },
  {
    id: 129,
    name: 'Marshall Islands (Former Marshall Islands District - Trust Territory of the Pacific Islands)',
    countryCode: 'MH',
    phoneCode: '+692',
  },
  {
    id: 130,
    name: 'Martinique (French) ',
    countryCode: 'MQ',
    phoneCode: '+596',
  },
  {
    id: 131,
    name: 'Mauritania ',
    countryCode: 'MR',
    phoneCode: '+222',
  },
  {
    id: 132,
    name: 'Mauritius ',
    countryCode: 'MU',
    phoneCode: '+230',
  },
  {
    id: 133,
    name: 'Mayotte (Territorial Collectivity of Mayotte)',
    countryCode: 'YT',
    phoneCode: '+269',
  },
  {
    id: 134,
    name: 'Mexico ',
    countryCode: 'MX',
    phoneCode: '+52',
  },
  {
    id: 135,
    name: 'Micronesia, Federated States of (Former Ponape, Truk, and Yap Districts - Trust Territory of the Pacific Islands)',
    countryCode: 'FM',
    phoneCode: '+691',
  },
  {
    id: 136,
    name: 'Moldova, Republic of',
    countryCode: 'MD',
    phoneCode: '+373',
  },
  {
    id: 137,
    name: 'Monaco, Principality of',
    countryCode: 'MC',
    phoneCode: '+377',
  },
  {
    id: 138,
    name: 'Monlia (Former Outer Monlia)',
    countryCode: 'MN',
    phoneCode: '+976',
  },
  {
    id: 139,
    name: 'Montserrat ',
    countryCode: 'MS',
    phoneCode: '+1-664',
  },
  {
    id: 140,
    name: 'Morocco ',
    countryCode: 'MA',
    phoneCode: '+212',
  },
  {
    id: 141,
    name: 'Mozambique (Former Portuguese East Africa)',
    countryCode: 'MZ',
    phoneCode: '+258',
  },
  {
    id: 142,
    name: 'Myanmar, Union of (Former Burma)',
    countryCode: 'MM',
    phoneCode: '+95',
  },
  {
    id: 143,
    name: 'Namibia (Former German Southwest Africa, South-West Africa)',
    countryCode: 'NA',
    phoneCode: '+264',
  },
  {
    id: 144,
    name: 'Nauru (Former Pleasant Island)',
    countryCode: 'NR',
    phoneCode: '+674',
  },
  {
    id: 145,
    name: 'Nepal ',
    countryCode: 'NP',
    phoneCode: '+977',
  },
  {
    id: 146,
    name: 'Netherlands ',
    countryCode: 'NL',
    phoneCode: '+31',
  },
  {
    id: 147,
    name: 'Netherlands Antilles (Former Curacao and Dependencies)',
    countryCode: 'AN',
    phoneCode: '+599',
  },
  {
    id: 148,
    name: 'New Caledonia ',
    countryCode: 'NC',
    phoneCode: '+687',
  },
  {
    id: 149,
    name: 'New Zealand (Aotearoa) ',
    countryCode: 'NZ',
    phoneCode: '+64',
  },
  {
    id: 150,
    name: 'Nicaragua ',
    countryCode: 'NI',
    phoneCode: '+505',
  },
  {
    id: 151,
    name: 'Niger ',
    countryCode: 'NE',
    phoneCode: '+227',
  },
  {
    id: 152,
    name: 'Nigeria ',
    countryCode: 'NG',
    phoneCode: '+234',
  },
  {
    id: 153,
    name: 'Niue (Former Savage Island)',
    countryCode: 'NU',
    phoneCode: '+683',
  },
  {
    id: 154,
    name: 'Norfolk Island ',
    countryCode: 'NF',
    phoneCode: '+672',
  },
  {
    id: 155,
    name: 'Northern Mariana Islands (Former Mariana Islands District - Trust Territory of the Pacific Islands)',
    countryCode: 'MP',
    phoneCode: '+1-670',
  },
  {
    id: 156,
    name: 'Norway ',
    countryCode: 'NO',
    phoneCode: '+47',
  },
  {
    id: 157,
    name: 'Oman, Sultanate of (Former Muscat and Oman)',
    countryCode: 'OM',
    phoneCode: '+968',
  },
  {
    id: 158,
    name: 'Pakistan (Former West Pakistan)',
    countryCode: 'PK',
    phoneCode: '+92',
  },
  {
    id: 159,
    name: 'Palau (Former Palau District - Trust Terriroty of the Pacific Islands)',
    countryCode: 'PW',
    phoneCode: '+680',
  },
  {
    id: 160,
    name: 'Palestinian State (Proposed)',
    countryCode: 'PS',
    phoneCode: '+970',
  },
  {
    id: 161,
    name: 'Panama ',
    countryCode: 'PA',
    phoneCode: '+507',
  },
  {
    id: 162,
    name: 'Papua New Guinea (Former Territory of Papua and New Guinea)',
    countryCode: 'PG',
    phoneCode: '+675',
  },
  {
    id: 163,
    name: 'Paraguay ',
    countryCode: 'PY',
    phoneCode: '+595',
  },
  {
    id: 164,
    name: 'Peru ',
    countryCode: 'PE',
    phoneCode: '+51',
  },
  {
    id: 165,
    name: 'Philippines ',
    countryCode: 'PH',
    phoneCode: '+63',
  },
  {
    id: 166,
    name: 'Poland ',
    countryCode: 'PL',
    phoneCode: '+48',
  },
  {
    id: 167,
    name: 'Portugal ',
    countryCode: 'PT',
    phoneCode: '+351',
  },
  {
    id: 168,
    name: 'Puerto Rico ',
    countryCode: 'PR',
    phoneCode: '+1-787 or +1-939',
  },
  {
    id: 169,
    name: 'Qatar, State of ',
    countryCode: 'QA',
    phoneCode: '+974 ',
  },
  {
    id: 170,
    name: 'Reunion (French) (Former Bourbon Island)',
    countryCode: 'RE',
    phoneCode: '+262',
  },
  {
    id: 171,
    name: 'Romania ',
    countryCode: 'RO',
    phoneCode: '+40',
  },
  {
    id: 172,
    name: 'Russian Federation ',
    countryCode: 'RU',
    phoneCode: '+7',
  },
  {
    id: 173,
    name: 'Rwanda (Rwandese Republic) (Former Ruanda)',
    countryCode: 'RW',
    phoneCode: '+250',
  },
  {
    id: 174,
    name: 'Saint Helena ',
    countryCode: 'SH',
    phoneCode: '+290',
  },
  {
    id: 175,
    name: 'Saint Kitts and Nevis (Former Federation of Saint Christopher and Nevis)',
    countryCode: 'KN',
    phoneCode: '+1-869',
  },
  {
    id: 176,
    name: 'Saint Lucia ',
    countryCode: 'LC',
    phoneCode: '+1-758',
  },
  {
    id: 177,
    name: 'Saint Pierre and Miquelon ',
    countryCode: 'PM',
    phoneCode: '+508',
  },
  {
    id: 178,
    name: 'Saint Vincent and the Grenadines ',
    countryCode: 'VC',
    phoneCode: '+1-784',
  },
  {
    id: 179,
    name: 'Samoa (Former Western Samoa)',
    countryCode: 'WS',
    phoneCode: '+685',
  },
  {
    id: 180,
    name: 'San Marino ',
    countryCode: 'SM',
    phoneCode: '+378',
  },
  {
    id: 181,
    name: 'Sao Tome and Principe ',
    countryCode: 'ST',
    phoneCode: '+239',
  },
  {
    id: 182,
    name: 'Saudi Arabia ',
    countryCode: 'SA',
    phoneCode: '+966',
  },
  {
    id: 183,
    name: 'Senegal ',
    countryCode: 'SN',
    phoneCode: '+221',
  },
  {
    id: 184,
    name: 'Seychelles ',
    countryCode: 'SC',
    phoneCode: '+248',
  },
  {
    id: 185,
    name: 'Sierra Leone ',
    countryCode: 'SL',
    phoneCode: '+232',
  },
  {
    id: 186,
    name: 'Singapore ',
    countryCode: 'SG',
    phoneCode: '+65',
  },
  {
    id: 187,
    name: 'Slovakia',
    countryCode: 'SK',
    phoneCode: '+421',
  },
  {
    id: 188,
    name: 'Slovenia ',
    countryCode: 'SI',
    phoneCode: '+386',
  },
  {
    id: 189,
    name: 'Solomon Islands (Former British Solomon Islands)',
    countryCode: 'SB',
    phoneCode: '+677',
  },
  {
    id: 190,
    name: 'Somalia (Former Somali Republic, Somali Democratic Republic) ',
    countryCode: 'SO',
    phoneCode: '+252',
  },
  {
    id: 191,
    name: 'South Africa (Former Union of South Africa)',
    countryCode: 'ZA',
    phoneCode: '+27',
  },
  {
    id: 192,
    name: 'Spain ',
    countryCode: 'ES',
    phoneCode: '+34',
  },
  {
    id: 193,
    name: 'Sri Lanka (Former Serendib, Ceylon) ',
    countryCode: 'LK',
    phoneCode: '+94',
  },
  {
    id: 194,
    name: 'Sudan (Former Anglo-Egyptian Sudan) ',
    countryCode: 'SD',
    phoneCode: '+249',
  },
  {
    id: 195,
    name: 'Suriname (Former Netherlands Guiana, Dutch Guiana)',
    countryCode: 'SR',
    phoneCode: '+597',
  },
  {
    id: 196,
    name: 'Swaziland, Kingdom of ',
    countryCode: 'SZ',
    phoneCode: '+268',
  },
  {
    id: 197,
    name: 'Sweden ',
    countryCode: 'SE',
    phoneCode: '+46',
  },
  {
    id: 198,
    name: 'Switzerland ',
    countryCode: 'CH',
    phoneCode: '+41',
  },
  {
    id: 199,
    name: 'Syria (Syrian Arab Republic) (Former United Arab Republic - with Egypt)',
    countryCode: 'SY',
    phoneCode: '+963',
  },
  {
    id: 200,
    name: 'Taiwan (Former Formosa)',
    countryCode: 'TW',
    phoneCode: '+886',
  },
  {
    id: 201,
    name: 'Tajikistan (Former Tajik Soviet Socialist Republic)',
    countryCode: 'TJ',
    phoneCode: '+992',
  },
  {
    id: 202,
    name: 'Tanzania, United Republic of (Former United Republic of Tanganyika and Zanzibar)',
    countryCode: 'TZ',
    phoneCode: '+255',
  },
  {
    id: 203,
    name: 'Thailand (Former Siam)',
    countryCode: 'TH',
    phoneCode: '+66',
  },
  {
    id: 204,
    name: 'Tokelau ',
    countryCode: 'TK',
    phoneCode: '+690',
  },
  {
    id: 205,
    name: 'Tonga, Kingdom of (Former Friendly Islands)',
    countryCode: 'TO',
    phoneCode: '+676',
  },
  {
    id: 206,
    name: 'Trinidad and Toba ',
    countryCode: 'TT',
    phoneCode: '+1-868',
  },
  {
    id: 207,
    name: 'Tunisia ',
    countryCode: 'TN',
    phoneCode: '+216',
  },
  {
    id: 208,
    name: 'Turkey ',
    countryCode: 'TR',
    phoneCode: '+90',
  },
  {
    id: 209,
    name: 'Turkmenistan (Former Turkmen Soviet Socialist Republic)',
    countryCode: 'TM',
    phoneCode: '+993',
  },
  {
    id: 210,
    name: 'Turks and Caicos Islands ',
    countryCode: 'TC',
    phoneCode: '+1-649',
  },
  {
    id: 211,
    name: 'Tuvalu (Former Ellice Islands)',
    countryCode: 'TV',
    phoneCode: '+688',
  },
  {
    id: 212,
    name: 'Uganda, Republic of',
    countryCode: 'UG',
    phoneCode: '+256',
  },
  {
    id: 213,
    name: 'Ukraine (Former Ukrainian National Republic, Ukrainian State, Ukrainian Soviet Socialist Republic)',
    countryCode: 'UA',
    phoneCode: '+380',
  },
  {
    id: 214,
    name: 'United Arab Emirates (UAE) (Former Trucial Oman, Trucial States)',
    countryCode: 'AE',
    phoneCode: '+971',
  },
  {
    id: 215,
    name: 'United Kingdom (Great Britain / UK)',
    countryCode: 'GB',
    phoneCode: '+44',
  },
  {
    id: 216,
    name: 'United States ',
    countryCode: 'US',
    phoneCode: '+1',
  },
  {
    id: 217,
    name: 'Uruguay, Oriental Republic of (Former Banda Oriental, Cisplatine Province)',
    countryCode: 'UY',
    phoneCode: '+598',
  },
  {
    id: 218,
    name: 'Uzbekistan (Former UZbek Soviet Socialist Republic)',
    countryCode: 'UZ',
    phoneCode: '+998',
  },
  {
    id: 219,
    name: 'Vanuatu (Former New Hebrides)',
    countryCode: 'VU',
    phoneCode: '+678',
  },
  {
    id: 220,
    name: 'Vatican City State (Holy See)',
    countryCode: 'VA',
    phoneCode: '+418',
  },
  {
    id: 221,
    name: 'Venezuela ',
    countryCode: 'VE',
    phoneCode: '+58',
  },
  {
    id: 222,
    name: 'Vietnam ',
    countryCode: 'VN',
    phoneCode: '+84',
  },
  {
    id: 223,
    name: 'Virgin Islands, British ',
    countryCode: 'VI',
    phoneCode: '+1-284',
  },
  {
    id: 224,
    name: 'Virgin Islands, United States (Former Danish West Indies) ',
    countryCode: 'VQ',
    phoneCode: '+1-340',
  },
  {
    id: 225,
    name: 'Wallis and Futuna Islands ',
    countryCode: 'WF',
    phoneCode: '+681',
  },
  {
    id: 226,
    name: 'Yemen ',
    countryCode: 'YE',
    phoneCode: '+967',
  },
  {
    id: 227,
    name: 'Zambia, Republic of (Former Northern Rhodesia) ',
    countryCode: 'ZM',
    phoneCode: '+260',
  },
  {
    id: 228,
    name: 'Zimbabwe, Republic of (Former Southern Rhodesia, Rhodesia) ',
    countryCode: 'ZW',
    phoneCode: '+263',
  },
];

export default countryOptions;
