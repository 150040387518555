import React, { useState } from 'react';
import {
  P, Flex, UL, LI, Row,
} from '@galilee/lilee';
import AccordionRow from 'components/AccordionRow';
import Container from 'components/Container';
import styled from 'styled-components/macro';
import { SectionTitle, SectionSubtitle } from 'components/MarketingTexts';

const Content = styled.div`
  width: 100%;
  max-width: 500px;
  flex: 1 0;
`;

const Inner = styled(Row)`
  max-width: 750px; 
`;

const Faq = () => {
  const [accordions, setAccordions] = useState({});
  const handleAccordionClick = (id) => { setAccordions((prev) => ({ ...prev, [id]: !prev[id] })); };

  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center" p={7} width="100%">
      <Inner alignItems="center">
        <Flex flexDirection="column" maxWidth="700px" mx="auto" mb="7">
          <Container alignItems="flex-start">
            <Flex flexDirection="column" width="100%">
              <SectionTitle uppercase>Data Collection and Privacy</SectionTitle>
              <SectionSubtitle fontSize={[4, null, null, 5]}>
                Frequently Asked Questions
              </SectionSubtitle>
            </Flex>
            <Content>
              <AccordionRow
                heading="What happens with my personal information?"
                isOpen={!!accordions[1]}
                onClick={() => handleAccordionClick(1)}
                headerProps={{ p: '18px 0', alignItems: 'flex-start' }}
              >
                <P md>
                  Keeping your identity safe and secure is our top concern.
                  As a result we only store the information necessary to comply with regulatory requirements to retain evidence of the identity of the person who has executed the document.
                  After which, we delete all sensitive information, reducing the risk of your details being compromised.
                </P>
              </AccordionRow>
              <AccordionRow
                heading="Why do I need to share my location?"
                isOpen={!!accordions[0]}
                onClick={() => handleAccordionClick(0)}
                headerProps={{ p: '18px 0', alignItems: 'flex-start' }}
              >
                <P md>
                  Identity verification uses several trust metrics to provide confidence to the verifier that you are who you say you are.
                  Location sharing helps us identify if you are in a foreign country or location other than the one expected.
                  Don&apos;t worry, we don&apos;t share your precise location, only the nearest town or city is used.
                </P>
              </AccordionRow>
              <AccordionRow
                heading="Why do I need to verify my identity?"
                isOpen={!!accordions[2]}
                onClick={() => handleAccordionClick(2)}
                headerProps={{ p: '18px 0', alignItems: 'flex-start' }}
              >
                <P md>
                  High quality identity verification is an important aspect of carrying out any higher value transaction. We&apos;ve been verifying identity in person for many years.
                  With the advent of digital transactions comes digital verification. The Livesign Identity verification is carried out to protect you and the companies that you interact with.
                  The rules for most financial transactions are enforced by various governmental bodies; all of which require us to verify your identity using a combination of different documents.
                </P>
              </AccordionRow>
              <AccordionRow
                heading="How does Livesign work?"
                isOpen={!!accordions[3]}
                onClick={() => handleAccordionClick(3)}
                headerProps={{ p: '18px 0', alignItems: 'flex-start' }}
              >
                <P md>
                  Livesign uses a combination of different technologies to verify your identity such as:
                </P>
                <UL my={0} fontSize={3}>
                  <LI>Document Verification Services: Checking government databases to ensure your document credentials are valid.</LI>
                  <LI>NFC Chip Reader: Allows us to read and verify the credentials on your passport and verify against the country of issue.</LI>
                  <LI>
                    Likeness checking: Using a selfie combined with your photo ID documents to ensure your likeness is verified.
                    The above is not a definitive list of verifications that we undertake, and the verifications vary for different identity documents, so we are unable to go through all the variations.
                    We do however guide you through the entire process step by step.
                  </LI>
                </UL>
              </AccordionRow>
              <AccordionRow
                heading="What documents do I need?"
                isOpen={!!accordions[4]}
                onClick={() => handleAccordionClick(4)}
                headerProps={{ p: '18px 0', alignItems: 'flex-start' }}
              >
                <P md>
                  The documents you need are different depending on the requirements of the company you are verifying your identity with.
                  The best documents to use are a Passport and Driving License. If you don&apos;t have one or more of those then we may require up to 3 or 4
                  identity documents such as, Birth Certificate, Change of Name Certificate, Medical ID Card or other government issued ID Documents in order to verify you as per the regulatory guidelines.
                </P>
              </AccordionRow>
              <AccordionRow
                heading="Why do I need to download the Livesign App?"
                isOpen={!!accordions[5]}
                onClick={() => handleAccordionClick(5)}
                headerProps={{ p: '18px 0', alignItems: 'flex-start' }}
              >
                <P md>
                  The Livesign App is needed so that we can make use of the near field communication (NFC) in your phone to read the details from your passport.
                  This is the most reliable means of verification as we can issue a request to verify this data against the country of origin.
                  Again, these details are only stored and displayed to the verifier until they approve the identity, after which all personally identifiable information is purged.
                </P>
              </AccordionRow>
              <AccordionRow
                heading="What are the minimum IT requirements?"
                isOpen={!!accordions[6]}
                onClick={() => handleAccordionClick(6)}
                headerProps={{ p: '18px 0', alignItems: 'flex-start' }}
              >
                <P md>The minimum requirements for the Livesign App are:</P>
                <UL my={1} fontSize={3}>
                  <LI>Apple iPhone 7 running iOS version 13 or later.</LI>
                  <LI>Android version 5.1 or later.</LI>
                </UL>
              </AccordionRow>
            </Content>
          </Container>
        </Flex>
      </Inner>
    </Flex>
  );
};

export default Faq;
