import {
  Flex, Icon, Panel, Row, Text,
} from '@galilee/lilee';
import React from 'react';
import styled from 'styled-components/macro';
import { useTransaction } from 'state/TransactionProvider';
import { TRANSACTION_STATUS } from 'utils';

const Subtitle = styled(Panel.Subtitle)`
  color: ${(p) => p.theme.colors.primary};
`;

const Progress = () => {
  const { state } = useTransaction();
  const { transaction } = state;
  const isComplete = transaction.status === TRANSACTION_STATUS.FINISHED;

  return (
    <Row collapseIndex={[2]}>
      <Row flexDirection="column">
        <Subtitle fontSize={2} fontWeight="bold" mb="none">
          Identity Verification
        </Subtitle>
        <Panel.Title fontSize={4}>
          Identity verification in progress
        </Panel.Title>
        <Text fontSize={3} mb={7}>
          {isComplete
            ? 'You can now close this window and return to your document signing.'
            : 'Please follow and complete the steps in the Livesign App.'}
        </Text>
        <Flex
          flexDirection="column"
          mb={[isComplete ? '32px' : '58px']}
        >
          {transaction.documents.map((document) => (
            <Flex
              alignItems="center"
              flexDirection="row"
              key={document}
              pl={2}
              mb={5}
            >
              <Icon color="success" name="CheckCircle" mr="11px" />
              <Text fontSize={3} fontWeight="medium">{`${document} complete`}</Text>
            </Flex>
          ))}
        </Flex>
      </Row>
    </Row>
  );
};

export default Progress;
