import React from 'react';
import ReactDOM from 'react-dom';
import { ToastNotifications } from '@galilee/lilee';
import RootProvider from 'state/RootProvider';
import logger, { applicationInsights } from 'logService';

import App from './App';

(async () => {
  applicationInsights.initialize(process.env.REACT_APP_APPLICATION_INSIGHTS_KEY);
})().then(() => {
  logger.event('application_started', { environment: process.env.NODE_ENV });
}).finally(() => {
  ReactDOM.render(
    <RootProvider>
      <ToastNotifications autoCloseDuration={5} />
      <App />
    </RootProvider>,
    document.getElementById('root'),
  );
});
