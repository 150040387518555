import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

/** @typedef {{ [key: string]: any }} CustomProperties */

/** @type {import("@microsoft/applicationinsights-web").ApplicationInsights} */
let appInsights;

const createTelemetryService = () => {
  const initialize = (/** @type {string} */ key) => {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: key,
        enableAutoRouteTracking: true,
        disableFetchTracking: false,
        enableAjaxErrorStatusText: true,
        enableUnhandledPromiseRejectionTracking: true,
      },
    });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((telemetry) => {
      // to avoid redundant page view telemetry being sent to Application Insights when a component re-renders.
      if (telemetry?.baseType === 'PageviewData' && telemetry?.baseData?.refUri === telemetry?.baseData?.uri) { return false; }
      if (telemetry?.baseData?.type === 'Fetch' && telemetry?.baseData?.success) { return false; }
      return true;
    });
  };

  return { appInsights, initialize };
};

/**
 * @param {string} message
 * @param {CustomProperties} customProperties
 */
const info = (message, customProperties = undefined) => {
  /** @type {import("@microsoft/applicationinsights-web").ITraceTelemetry} */
  const telemetry = {
    message,
    severityLevel: SeverityLevel.Information,
  };

  appInsights?.trackTrace(telemetry, customProperties);
};

/**
 * @param {string} message
 * @param {CustomProperties} customProperties
 */
const warning = (message, customProperties = undefined) => {
  const telemetry = {
    message,
    severityLevel: SeverityLevel.Warning,
  };

  appInsights?.trackTrace(telemetry, customProperties);
};

/**
 * @param {string} message
 * @param {CustomProperties} customProperties
 */
const error = (message, customProperties = undefined) => {
  const telemetry = {
    message,
    severityLevel: SeverityLevel.Error,
  };

  appInsights?.trackTrace(telemetry, customProperties);
};

/** @typedef {{ message: string, stacktrace: string, token: string }} CriticalErrorPayload */

/**
 * @param {string} message
 * @param {CriticalErrorPayload & CustomProperties} customProperties
 */
const critical = (message, customProperties) => {
  const telemetry = {
    message,
    severityLevel: SeverityLevel.Critical,
  };

  appInsights?.trackTrace(telemetry, customProperties);
};

/**
 * @param {string} name
 * @param {CustomProperties} customProperties
 */
const event = (name, customProperties = undefined) => {
  const telemetry = {
    name,
    properties: customProperties,
  };
  appInsights?.trackEvent(telemetry);
};

const logger = {
  info,
  warning,
  error,
  critical,
  event,
};

export const applicationInsights = createTelemetryService();
export default logger;
