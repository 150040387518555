import {
  Flex, Link, Column, H2, P, Row,
} from '@galilee/lilee';
import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as FourOhFourImg } from 'images/fourOhFourImg.svg';

const Inner = styled(Row)`
  max-width: 750px; 
`;

const Wrapper = styled(Flex)`
  height: 100%;
`;

const FourOhFour = () => (
  <Wrapper alignItems="center" flexDirection="column" height="100%" justifyContent="center" p={7} width="100%">
    <Inner alignItems="center" collapseIndex="0">
      <Column>
        <H2 mb={6}>Oops we can&apos;t find that page</H2>
        <P fontSize={3} fontWeight="medium">
          { 'Maybe try the Livesign ' }
          <Link display="inline-block" color="base" href="https://live-sign.com" pl="0" fontSize={3} fontWeight="medium">home page</Link>
        </P>
      </Column>
      <FourOhFourImg />
    </Inner>
  </Wrapper>
);

export default FourOhFour;
