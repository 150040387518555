import React from 'react';
import PropTypes from 'prop-types';
import {
  theme,
  CustomThemeProvider,
  themeOverrides,
  override,
} from '@galilee/lilee';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from 'components/GlobalStyle';
import ErrorBoundary from 'components/ErrorBoundary';
import ApplicationProvider from './ApplicationProvider';

const liveSignThemeOverrides = {
  name: 'liveSign',
  fonts: {
    body: "'Inter', sans-serif",
    title: "'Inter', sans-serif",
  },
  fontWeights: {
    text: 350, light: 300, medium: 400, bold: 600,
  },
  lineHeights: { standard: 1.5, display: 1.2, compact: 1 },
  fontSizes: ['10px', '12px', '16px', '18px', '24px', '28px', '36px', '48px', '52px'],
  space: [
    '0', //      0
    '2px', //    1
    '4px', //    2
    '8px', //    3
    '12px', //   4
    '16px', //   5
    '24px', //   6
    '32px', //   7
    '48px', //   8
    '64px', //   9
    '72px', //   10
    '160px', //  11
    '260px', //  12
  ],
  threshold: {
    sectionMaxWidth: '1200px',
    contentMaxWidth: '760px',
    inputMaxWidth: '450px',
  },
  colors: {
    ...themeOverrides,

    primaryDark: '#299e04',
    primary: '#2DB004',
    primary80: '#57c036',
    primary60: '#81d068',
    primary40: '#abdf9b',
    primary20: '#d5efcd',
    primary10: '#eaf7e6',

    secondaryDark: '#2266a1',
    secondary: '#2671B3',
    secondary80: '#518dc2',
    secondary60: '#7daad1',
    secondary40: '#a8c6e1',
    secondary20: '#d4e3f0',
    secondary10: '#e9f1f7',

    tertiaryDark: '#6ee349',
    tertiary: '#7AFC51',
    tertiary80: '#95fd74',
    tertiary60: '#affd97',
    tertiary40: '#cafeb9',
    tertiary20: '#e4fedc',
    tertiary10: '#f2ffee',

    success: '#14CC9E',
    successLight: '#e8faf5',
    successDark: '#063d2f',

    warn: '#FF5119',
    warnLight: '#ffeee8',
    warnDark: '#4c1807',

    error: '#b0230c',
    errorLight: '#f7e9e7',
    errorDark: '#350a04',
  },
};

export const liveSignTheme = override(theme, liveSignThemeOverrides);

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const RootProvider = ({ children }) => (
  <ApplicationProvider>
    <CustomThemeProvider theme={liveSignTheme}>
      <BrowserRouter basename={baseUrl}>
        <GlobalStyle />
        <ErrorBoundary>{children}</ErrorBoundary>
      </BrowserRouter>
    </CustomThemeProvider>
  </ApplicationProvider>
);

RootProvider.defaultProps = {
  children: null,
};

RootProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default RootProvider;
