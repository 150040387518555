import {
  Column, Flex,
} from '@galilee/lilee';
import React, { useState } from 'react';

import CancelButton from 'components/CancelButton';
import CancelModal from 'components/CancelModal';
import logger from 'logService';

const HostDisplay = () => {
  const [cancelModal, setCancelModal] = useState(false);

  const onClose = () => {
    setCancelModal(false);
  };

  const onConfirm = () => {
    if (window.opener) {
      logger.event('cancel_verification');
      // close the modal
      window.opener.postMessage('cancelled', '*');
      window.close();
    }
  };

  return (
    <Flex alignItems="center" flexDirection="row">
      {cancelModal && <CancelModal onClose={onClose} onConfirm={onConfirm} />}
      <Column mb="none" mr="14px">
        <CancelButton
          color="secondary"
          disableHover
          fontSize="16px"
          onClick={() => setCancelModal(true)}
          textTransform="none"
        >
          Cancel Verification
        </CancelButton>
      </Column>
    </Flex>
  );
};

export default HostDisplay;
