/* eslint-disable no-nested-ternary */
import React from 'react';
import { Flex, Icon } from '@galilee/lilee';
import styled from 'styled-components/macro';
import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';

const AccordionWrapper = styled(Flex)`
  cursor: pointer;
  font-size: ${((p) => themeGet(`fontSizes.${p.fontSize}`, p.fontSize)(p))};
`;

AccordionWrapper.defaultProps = {
  flexDirection: 'column',
  height: 'auto',
  justifyContent: 'center',
};

const AccordionHeader = styled(Flex)`
  font-size: ${((p) => themeGet(`fontSizes.${p.headingFontSize}`, p.headingFontSize)(p))};
  font-weight: ${(p) => p.theme.fontWeights[p.headingFontWeight]};
  transition: all 0.3s ease-in-out;
`;

AccordionHeader.defaultProps = {
  alignItems: 'center',
  justifyContent: 'space-between',
  p: '18px 30px',
};

const InternalWrapper = styled.div`
  ${(p) => (p.contentBg ? `background-color: ${p.theme.colors}[${p.contentBg}]` : '')};
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? '1000px' : '0')};
  ${(p) => (p.isOpen && p.type === 'task' ? 'padding: 32px 32px 25px 32px' : p.type === 'task' ? 'padding: 0 32px' : '')};
  transition: all 0.3s ease-in-out;
  width: 100%;
`;

const HeaderIcon = styled(Icon)`
  transform: rotate(${(props) => (props.isOpen ? '90' : '0')}deg);
  transition: all 0.2s;
  fill: ${(props) => (props.isOpen ? props.theme.colors.base : props.theme.colors.base40)};
`;

const DefaultHeaderIcon = styled(Icon)`
  transform: rotate(${(props) => (props.isOpen ? '180' : '0')}deg);
  transition: all 0.2s;
`;

const RemoveIcon = styled(Icon)`
  cursor: pointer;
`;

const AccordionRow = ({
  headerProps, contentBg, type, fontSize, heading, headingFontSize, headingFontWeight, children, isOpen, isRemovable, dismiss, isComplete, ...rest
}) => (
  <AccordionWrapper {...rest} fontSize={fontSize} isComplete={isComplete} flexDirection="column">
    <AccordionHeader {...headerProps} isOpen={isOpen} headingFontSize={headingFontSize} headingFontWeight={headingFontWeight}>
      <Flex alignItems="center" flexDirection="row">
        {type === 'task' && isComplete ? <Icon name="CheckCircle" size="18px" mr={3} color="success" /> : null}
        {type === 'task' && !isComplete ? <HeaderIcon isOpen={isOpen} name="NavRight" size="12px" mr={3} /> : null}
        {heading}
      </Flex>
      {isComplete && type === 'task' ? <RemoveIcon color="base60" name="Cross" onClick={dismiss} size="18px" /> : null}
      {isRemovable && isOpen && !isComplete && type === 'task' ? <RemoveIcon color="base60" name="Cross" onClick={dismiss} size="18px" /> : null}
      {type === 'default' ? <DefaultHeaderIcon color="secondary" isOpen={isOpen} name="NavDown" size="12px" /> : null}
    </AccordionHeader>
    <InternalWrapper isOpen={isOpen} contentBg={contentBg} type={type}>
      {children}
    </InternalWrapper>
  </AccordionWrapper>
);

AccordionRow.defaultProps = {
  headerProps: {},
  contentBg: 'transparent',
  dismiss: null,
  onClick: undefined,
  fontSize: 3,
  headingFontSize: '',
  headingFontWeight: 'bold',
  isComplete: false,
  isRemovable: false,
  type: 'default',
};

AccordionRow.propTypes = {
  children: PropTypes.shape().isRequired,
  headerProps: PropTypes.shape({}),
  contentBg: PropTypes.string,
  dismiss: PropTypes.func,
  onClick: PropTypes.func,
  heading: PropTypes.string.isRequired,
  headingFontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  headingFontWeight: PropTypes.string,
  fontSize: PropTypes.string,
  isComplete: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isRemovable: PropTypes.bool,
  type: PropTypes.oneOf(['task', 'default']),
};

export default AccordionRow;
