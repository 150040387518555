import React from 'react';
import { Flex } from '@galilee/lilee';

import { ReactComponent as LiveSignLogo } from 'images/liveSignLogo.svg';
import HostDisplay from './HostDisplay';

const Header = () => (
  <Flex
    alignItems="center"
    justifyContent="space-between"
    pb="7"
    pl={[0, 5]}
    pr={[0, 5]}
    width="100%"
  >
    <LiveSignLogo />
    <HostDisplay />
  </Flex>
);
export default Header;
