import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
}

body {
  border: 0;
  color: ${(p) => p.theme.colors.base};
  font-family: ${(p) => p.theme.fonts.body};
  font-weight: 300;
  margin: 0;
  overflow-x: hidden;
}

h1 { letter-spacing: 0 !important; }
h2 { letter-spacing: 0 !important; }
h3 { letter-spacing: 0 !important; }
h4 { 
  letter-spacing: 0 !important; 
  font-size: ${(p) => p.theme.fontSizes[1]} !important;
  margin-bottom: ${(p) => p.theme.space[2]} !important;
}
h5 { letter-spacing: 0 !important; }
h6 { letter-spacing: 0 !important; }

p { font-size: ${(p) => p.theme.fontSizes[2]} !important; }


html, body, #root, #root > div {
  height: 100%;
}
`;

export default GlobalStyle;
