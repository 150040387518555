import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import FourOhFour from 'screens/FourOhFour';
import Main from 'screens/Main';
import Marketing from 'screens/Marketing';

const onBeforeUnload = (e) => {
  e.preventDefault();
  if (window.opener) window.opener.postMessage('cancelled', '*');
};

const App = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return (
      () => {
        window.removeEventListener('beforeunload', onBeforeUnload);
      }
    );
  }, []);

  return (
    <Switch>
      <Route path="/get-started/:transactionId" component={Main} />
      <Route exact path="/verify/:transactionId" component={Main} />
      <Route path="/" component={Marketing} />
      <Route component={FourOhFour} />
    </Switch>
  );
};

export default App;
