import React from 'react';
import styled from 'styled-components/macro';
import { Absolute, Box, mediaQueries } from '@galilee/lilee';
import { ReactComponent as LiveSignLogoSquare } from 'images/liveSignLogoSquare.svg';
import { ReactComponent as AppStoreLogo } from 'images/appStoreLogo.svg';
import { ReactComponent as PlayStoreLogo } from 'images/playStoreLogo.svg';
import { isAndroidDevice } from 'utils';

const { greaterThan } = mediaQueries;

const MobileLogo = styled(Absolute)`
  top: -46px;
  width: 100%;
  margin-left: -${(p) => p.theme.space[7]};
  display: flex;
  gap: ${(p) => p.theme.space[5]};
  flex-flow: column nowrap;
  align-items: center;

  ${greaterThan[0]`
    margin-left: -${(p) => p.theme.space[9]};
  `}
`;
const LogoContainer = styled(Box)`
  background-color: ${(p) => p.theme.colors.base};
  padding: ${(p) => p.theme.space[3]};
  border-radius: 25px;
  display: flex;
  margin: auto;
`;

export default function HeaderMobile() {
  const androidDevice = isAndroidDevice();

  return (
    <MobileLogo>
      <LogoContainer><LiveSignLogoSquare /></LogoContainer>
      {androidDevice
        ? <PlayStoreLogo />
        : <AppStoreLogo />}
    </MobileLogo>
  );
}
