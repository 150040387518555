import {
  Button, H2, H4, Modal, Text,
} from '@galilee/lilee';
import PropTypes from 'prop-types';
import React from 'react';

const CancelModal = ({ onClose, onConfirm }) => (
  <Modal p={[7, 8]}>
    <Modal.Close onClose={onClose} />
    <Modal.Body>
      <H4 color="primary" fontWeight="bold" mb={2}>
        Identity Verification
      </H4>
      <H2>Are you sure you want to cancel?</H2>
      <Text fontSize={3} mb="54px" pt="28px">
        By cancelling you will lose any progress you may have made with your
        ID Verification. You will be able to reattempt your verification at
        any time.
      </Text>
      <Button fontWeight="bold" onClick={onConfirm} mr={[0, 6]}>
        yes, cancel
      </Button>
    </Modal.Body>
  </Modal>
);

CancelModal.defaultProps = {
  onClose: null,
  onConfirm: null,
};

CancelModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default CancelModal;
