// eslint-disable-next-line no-restricted-globals
const hasMobileSize = () => (screen?.availWidth && screen?.availHeight) && Math.min(screen.availWidth, screen.availHeight) < 500;
const hasTouchScreen = () => ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
export const detectMobileDevice = () => (hasMobileSize() && hasTouchScreen());

export function isAndroidDevice() {
  return ![
    'iPhone Simulator',
    'iPad',
    'iPhone',
  ].includes(navigator.userAgentData?.platform)
  // iPad on iOS 13 detection
  && !(navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}
