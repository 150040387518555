import PropTypes from 'prop-types';
import React from 'react';
import { PAGE } from 'utils';
import { PageLoading } from '@galilee/lilee';
import Progress from './Progress';
import Started from './Started';

export default function Routing({ page }) {
  switch (page) {
    case PAGE.STARTED:
      return <Started />;
    case PAGE.PROGRESS:
      return <Progress />;
    case PAGE.TIME_OUT_SCREEN:
      return <Started />;
    default:
      return <PageLoading />;
  }
}

Routing.propTypes = {
  page: PropTypes.oneOf(Object.values(PAGE)).isRequired,
};
