import React, {
  createContext, useContext, useEffect, useReducer, useState,
} from 'react';
import { toast } from '@galilee/lilee';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { fetchTransactionAction } from 'actions/Transaction';
import confirmMobileNumber from 'actions/Setup';
import { useApplication } from 'state/ApplicationProvider';
import { PAGE } from 'utils';
import CountryCodes from 'components/PhoneInput/countryOptions';
import logger from 'logService';

const TransactionContext = createContext(null);

const getInitialState = (id, initialiseMobile, initCountryCodeId, initPhoneCode) => ({
  page: PAGE.INITIATE,
  transactionId: id,
  mobile: {
    initial: initialiseMobile,
    initCountryCodeId,
    initPhoneCode,
    number: `${initPhoneCode}${initialiseMobile}`,
    isValid: null,
  },
  transaction: {
    documents: [],
    status: null,
  },
  isInviteSent: false,
  error: null,
});

const mapMobileData = (mobileNumber, countryCode, countryPhoneCodeId, state) => {
  if (!mobileNumber || !countryCode || !countryPhoneCodeId) return { ...state.mobile, valid: true };
  const countryCodeId = CountryCodes.find((x) => x.id === countryPhoneCodeId)?.id;
  const initial = countryCode ? mobileNumber.substring(countryCode.length) : '';

  return {
    initial,
    initCountryCodeId: countryCodeId,
    initPhoneCode: countryCode,
    number: mobileNumber,
    isValid: true,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TRANSACTION': {
      return {
        ...state,
        mobile: mapMobileData(action.payload.mobile, action.payload.countryPhoneCode, action.payload.countryCodeId, state),
        transactionId: action.payload.transactionId,
        transaction: {
          documents: action.payload.documentVoiCaptureComplete,
          status: action.payload.transactionStatus,
        },
        page: state.mobile?.number ? PAGE.STARTED : PAGE.SETUP,
      };
    }
    case 'UPDATE_TRANSACTION': {
      return {
        ...state,
        transaction: {
          documents: action.payload.documentVoiCaptureComplete,
          status: action.payload.transactionStatus,
        },
        page: action.payload.documentVoiCaptureComplete?.length > 0 ? PAGE.PROGRESS : PAGE.STARTED,
      };
    }
    case 'SET_MOBILE': {
      return {
        ...state,
        mobile: action.payload,
      };
    }
    case 'BACK_TO_SETUP': {
      return {
        ...state,
        page: PAGE.SETUP,
      };
    }
    case 'SET_LINK_SENT': {
      return {
        ...state,
        isInviteSent: !!action.payload,
        page: PAGE.STARTED,
        error: action.payload ? null : 'We couldn\'t send an invite, please try the process again',
      };
    }
    case 'FALLBACK_PAGE': {
      return {
        ...state,
        page: PAGE.TIME_OUT_SCREEN,
      };
    }
    default:
      return state;
  }
};

const createHubConnection = (transactionId) => new HubConnectionBuilder()
  .withUrl(`${process.env.REACT_APP_SERVER_URL}/transactionHub?transactionId=${transactionId}`)
  .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000, 120000, 180000])
  .build();

const useTransactionHook = (transactionId, initialiseMobile, initCountryCodeId, initPhoneCode) => {
  const init = getInitialState(transactionId, initialiseMobile, initCountryCodeId, initPhoneCode);
  const [state, dispatch] = useReducer(reducer, init);
  const { dispatch: applicationDispatch } = useApplication();
  const [initialised, setInitialised] = useState(false);
  const [isConfirmingMobile, setIsConfirmingMobile] = useState(false);
  const [isMobileConfirmed, setIsMobileConfirmed] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (!initialiseMobile || !transactionId || !initPhoneCode || !initCountryCodeId) return;
    const newMobile = getInitialState(transactionId, initialiseMobile, initCountryCodeId, initPhoneCode).mobile;
    dispatch({
      type: 'SET_MOBILE',
      payload: { ...newMobile, isValid: true },
    });
  }, [initCountryCodeId, initPhoneCode, initialiseMobile, transactionId]);

  useEffect(() => {
    const transId = transactionId || params?.transactionId;
    if (!transId) return;
    async function fetchData() {
      setInitialised(true);
      try {
        const data = await fetchTransactionAction(
          applicationDispatch,
          transId,
        );
        if (!data) return;
        dispatch({ type: 'SET_TRANSACTION', payload: { ...data, transactionId: transId } });
        logger.event('transaction_fetched', { transactionId: transId });
      } catch (error) {
        logger.error('transaction_fetch_error', { transactionId: transId, error });
      }
    }
    if (!initialised) fetchData();
  }, [transactionId, initialised, applicationDispatch, params.transactionId]);

  // handle link device
  useEffect(() => {
    if (!state.transactionId) return;
    const linkMobile = async ({ transactionId: id }) => {
      try {
        setIsConfirmingMobile(true);
        const result = await confirmMobileNumber({
          transactionId: id,
        });
        if (!result) throw new Error('Confirm mobile number failed');
        setIsMobileConfirmed(true);
        dispatch({ type: 'SET_LINK_SENT', payload: true });
        logger.event('mobile_linked', { transactionId: id });
      } catch (error) {
        setIsMobileConfirmed(false);
        toast.error('We couldn\'t confirm your mobile number, please try the process again');
        logger.error('mobile_link_error', {
          transactionId: id, error,
        });
      } finally {
        setIsConfirmingMobile(false);
      }
    };
    linkMobile({ transactionId: state.transactionId });
  }, [state.transactionId]);

  useEffect(() => {
    if (!state.transactionId) return () => {};
    // Create websocket connection
    const connection = createHubConnection(state.transactionId);

    connection.onreconnecting(() => applicationDispatch({
      type: 'SET_WEBSOCKET_DISCONNECTION_ERROR',
      payload: 'Transaction Progress',
    }));
    connection.onreconnected(() => applicationDispatch({
      type: 'REMOVE_WEBSOCKET_DISCONNECTION_ERROR',
      payload: 'Transaction Progress',
    }));
    connection.on('transactionProgress', (transactionProgress) => {
      dispatch({
        type: 'UPDATE_TRANSACTION',
        payload: transactionProgress,
      });
    });
    connection
      .start()
      .then(() => {
        logger.event('websocket_connected', { transactionId: state.transactionId });
        applicationDispatch({
          type: 'REMOVE_WEBSOCKET_ERROR',
          payload: 'Transaction Progress',
        });
      })
      .catch(() => {
        logger.error('websocket_connection_error', { transactionId: state.transactionId });
        applicationDispatch({
          type: 'SET_WEBSOCKET_ERROR',
          payload: 'Transaction Progress',
        });
      });
    return () => { connection.stop(); };
  }, [dispatch, applicationDispatch, state.transactionId]);

  return {
    state, dispatch, isConfirmingMobile, isMobileConfirmed,
  };
};

export const useTransaction = () => {
  const contextValue = useContext(TransactionContext);
  if (contextValue === null) throw Error('TransactionContext has not been provided!');
  return contextValue;
};

const TransactionProvider = ({
  transactionId, initialiseMobile, initCountryCodeId, initPhoneCode, children,
}) => {
  const value = useTransactionHook(transactionId, initialiseMobile, initCountryCodeId, initPhoneCode);
  return (
    <TransactionContext.Provider value={value}>
      {children}
    </TransactionContext.Provider>
  );
};

TransactionProvider.defaultProps = {
  children: null,
  initialiseMobile: null,
  initCountryCodeId: 13,
  initPhoneCode: '+61',
};

TransactionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  transactionId: PropTypes.string.isRequired,
  initialiseMobile: PropTypes.string,
  initCountryCodeId: PropTypes.number,
  initPhoneCode: PropTypes.string,
};

export default TransactionProvider;
