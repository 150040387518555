import React, {
  createContext,
  useContext,
  useReducer,
} from 'react';
import { base64EncodeUnicode } from 'utils';

const ApplicationContext = createContext();

const initialState = {
  toastNotifications: [],
  socketConnectionErrors: {},
  disconnectionErrors: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TOAST_NOTIFICATION': {
      const id = base64EncodeUnicode(action.payload.message);
      if (state.toastNotifications.some((n) => n.id === id)) return state;
      return {
        ...state,
        toastNotifications: [
          ...state.toastNotifications,
          { id, ...action.payload },
        ],
      };
    }
    case 'REMOVE_TOAST_NOTIFICATION': {
      const toastNotifications = state.toastNotifications.filter(
        (notification) => notification.id !== action.payload,
      );
      return { ...state, toastNotifications };
    }
    case 'SET_WEBSOCKET_DISCONNECTION_ERROR': {
      const id = base64EncodeUnicode(action.payload);
      return {
        ...state,
        disconnectionErrors: {
          ...state.disconnectionErrors,
          [id]: action.payload,
        },
      };
    }
    case 'REMOVE_WEBSOCKET_DISCONNECTION_ERROR': {
      const payloadId = base64EncodeUnicode(action.payload);
      const disconnectionErrors = {};
      Object.keys(state.disconnectionErrors).forEach((id) => {
        if (id !== payloadId) { disconnectionErrors[id] = state.disconnectionErrors[id]; }
      });
      return { ...state, disconnectionErrors };
    }
    case 'SET_WEBSOCKET_ERROR': {
      const id = base64EncodeUnicode(action.payload);
      return {
        ...state,
        socketConnectionErrors: {
          ...state.socketConnectionErrors,
          [id]: action.payload,
        },
      };
    }
    case 'REMOVE_WEBSOCKET_ERROR': {
      const payloadId = base64EncodeUnicode(action.payload);
      const socketConnectionErrors = {};
      Object.keys(state.socketConnectionErrors).forEach((id) => {
        if (id !== payloadId) { socketConnectionErrors[id] = state.socketConnectionErrors[id]; }
      });
      return { ...state, socketConnectionErrors };
    }
    default:
      return state;
  }
};

const useApplicationHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return {
    state: {
      ...state,
      addNotification: (message, type) => {
        dispatch({
          type: 'SET_TOAST_NOTIFICATION',
          payload: { message, type },
        });
      },
    },
    dispatch,
  };
};
export const useApplication = () => useContext(ApplicationContext);

const ApplicationProvider = (props) => {
  const value = useApplicationHook();
  return <ApplicationContext.Provider value={value} {...props} />;
};

export default ApplicationProvider;
