import {
  Box, Column, P, Text, HR, Button, H2, Icon, Row,
} from '@galilee/lilee';
import QRCode from 'react-qr-code';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTransaction } from 'state/TransactionProvider';
import SectionTitle from 'components/SectionTitle';
import { detectMobileDevice, isAndroidDevice } from 'utils';
import MetaTags from 'react-meta-tags';
import Subtitle from 'components/Subtitle';
import 'components/PhoneInput/flags.css';

const DeepLinks = {
  android: {
    appLink: 'intent://open/#Intent;scheme=livesign;package=com.thelawstore.livesign;end',
    downloadLink: 'https://play.google.com/store/apps/details?id=com.thelawstore.livesign',
  },
  ios: {
    appLink: 'livesign://path/',
    downloadLink: 'https://apps.apple.com/us/app/livesign/id1568409284',
  },
};

const MobileTopSection = styled(Box)``;
MobileTopSection.Content = styled(Column)`
  width: 100%;
  position: relative;
  align-items: center;
`;
MobileTopSection.EditIcon = styled.span`
  position: absolute;
  right: 0;
  top: 10px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.colors.base10};
`;
MobileTopSection.Title = styled(H2)`
  text-decoration: none !important;
`;
const LeftColumn = styled(Column)`
  padding-right: 30px;
`;
const RightColumn = styled(Column)`
  padding-left: 30px;
`;

const Started = () => {
  const {
    isMobileConfirmed,
    isConfirmingMobile,
  } = useTransaction();
  const qrCode = window.location.toString();
  console.log(qrCode);
  const mobileJourney = detectMobileDevice();
  const isAndroid = isAndroidDevice();
  const storeName = isAndroid ? 'Google Play Store' : 'AppStore';
  const downloadLink = isAndroid
    ? DeepLinks.android.downloadLink
    : DeepLinks.ios.downloadLink;

  useEffect(() => {
    if (!isMobileConfirmed) return;
    const a = document.createElement('a');
    a.href = downloadLink;
  }, [downloadLink, isMobileConfirmed]);

  const handleOpenInAppStore = async () => {
    window.navigator.clipboard.writeText(window.location);
    window.location.assign(downloadLink);
  };

  return (
    <Column>
      <MetaTags>
        <meta name="apple-itunes-app" content="app-id=1568409284" />
      </MetaTags>
      {!mobileJourney && (
        <>
          <Box>
            <Subtitle fontSize={2} fontWeight="bold" mb="none">
              Identity Verification
            </Subtitle>
            <SectionTitle>Let&apos;s get you set up</SectionTitle>
            <P fontSize={3} lineHeight="1.2">
              You&apos;ll need the Livesign App to verify your identity. Please open the camera on your smart phone and scan the QR code or open the Livesign App and select “Scan QR Code”.
            </P>
            <div style={
              {
                height: 'auto', paddingTop: '10px', maxWidth: '250px', width: '100%',
              }
            }
            >
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={qrCode}
              />
            </div>
          </Box>
          <HR my="5" width="100%" />
        </>
      )}

      {mobileJourney && (
        <>
          <SectionTitle>Download the Livesign App</SectionTitle>
          <Text lineHeight="1.2" fontSize={3}>{`Please follow button to be directed to the ${storeName}.`}</Text>
          <Button
            my="5"
            fontWeight="bold"
            width="100%"
            color="secondary"
            onClick={handleOpenInAppStore}
            disabled={isConfirmingMobile}
          >
            {isConfirmingMobile
              ? <Icon name="Spinner" spin />
              : 'Open in app store'}
          </Button>
          <HR my="5" width="100%" />
          <SectionTitle>Can&apos;t install the app?</SectionTitle>
          <Text lineHeight="1.2" fontSize={3}>It&apos;s likely that your phone is incompatible. Please borrow another device and scan the QR code.</Text>
          <div style={
            {
              height: 'auto', paddingTop: '10px', maxWidth: '50%', width: '100%',
            }
          }
          >
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={qrCode}
            />
          </div>
          <HR my="5" width="100%" />
          <SectionTitle>Phone Requirements</SectionTitle>
          <Text fontSize={3} lineHeight="1.2">Livesign App is compatible with the following Smartphones:</Text>
          <ul>
            <li><Text fontSize={3} lineHeight="1.2">iPhone 7 or newer</Text></li>
            <li><Text fontSize={3} lineHeight="1.2">Android version 8 or greater</Text></li>
          </ul>
        </>
      )}
      {!mobileJourney && (
        <>
          <Row>
            <LeftColumn>
              <SectionTitle>Can&apos;t install the app?</SectionTitle>
              <Text lineHeight="1.2" fontSize={3}>It&apos;s likely that your phone is incompatible. Please borrow another device and scan the QR code.</Text>
            </LeftColumn>
            <RightColumn>
              <SectionTitle>Phone Requirements</SectionTitle>
              <Text fontSize={3} lineHeight="1.2">Livesign App is compatible with the following Smartphones:</Text>
              <ul>
                <li><Text fontSize={3} lineHeight="1.2">iPhone 7 or newer</Text></li>
                <li><Text fontSize={3} lineHeight="1.2">Android version 8 or greater</Text></li>
              </ul>
            </RightColumn>
          </Row>
        </>
      )}
    </Column>
  );
};

export default Started;
