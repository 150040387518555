import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FourOhFour from 'screens/FourOhFour';
import Privacy from './Privacy';
import Faq from './Faq';
import Terms from './Terms';

const App = () => (
  <Switch>
    <Route exact path="/privacy" component={Privacy} />
    <Route exact path="/faq" component={Faq} />
    <Route exact path="/terms" component={Terms} />
    <Route component={FourOhFour} />
  </Switch>
);

export default App;
