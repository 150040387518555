import {
  Box, Panel, toast,
} from '@galilee/lilee';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  TRANSACTION_STATUS, detectMobileDevice, LIVESIGN_STORAGE_KEY, PAGE, MAX_TIME_OUT,
} from 'utils';
import TransactionProvider, { useTransaction } from 'state/TransactionProvider';
import Header from './components/Header';
import HeaderMobile from './components/HeaderMobile';
import Footer from './components/Footer';
import Routing from './Router';

const Wrapper = styled(Panel)`
  padding-top: 32px;
  border-radius: 0;
  background-color: ${(p) => p.theme.colors.base10};
  min-height: 100%;
  max-width: none;
  overflow: auto;
  position: relative;
`;

const ContentWrapper = styled(Box)`
  position: relative;
`;
ContentWrapper.HeaderMobile = HeaderMobile;

// TODO: security filter for messages only from allowed host domains
const host = '*';

const PageContent = () => {
  const { state, dispatch } = useTransaction();
  const [isTimedOut, setIsTimedOut] = useState(false);
  const mobileJourney = detectMobileDevice();

  const sendStatus = (status) => {
    switch (status) {
      case TRANSACTION_STATUS.CANCELLED:
        if (window.opener) window.opener.postMessage('cancelled', `${host}`);
        window.close();
        break;
      case TRANSACTION_STATUS.ERRORED:
        if (window.opener) window.opener.postMessage('error', `${host}`);
        window.close();
        break;
      case TRANSACTION_STATUS.FINISHED:
        if (window.opener) window.opener.postMessage('complete', `${host}`);
        window.close();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimedOut(true);
    }, MAX_TIME_OUT);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (state.page && state.page !== PAGE.INITIATE) { return; }
    const fallbackPage = () => { dispatch({ type: 'FALLBACK_PAGE' }); };
    if (isTimedOut) {
      fallbackPage();
    }
  }, [dispatch, isTimedOut, state.page]);

  useEffect(() => {
    sendStatus(state.transaction.status);
  }, [state.transaction.status]);

  useEffect(() => {
    if (!state.error?.length) return;
    toast.error(state.error);
  }, [state.error]);

  return (
    <Wrapper>
      {!mobileJourney && <Header />}
      <ContentWrapper px={[7, 9]} py="9" backgroundColor="white" borderRadius="3">
        {mobileJourney && <ContentWrapper.HeaderMobile />}
        <Box pt={mobileJourney ? 9 : 0}>
          <Routing page={state.page} />
        </Box>
      </ContentWrapper>
      <Footer />
    </Wrapper>
  );
};

const Main = () => {
  const [message, setMessage] = useState({
    transactionId: '',
    initialiseMobile: '',
    initCountryCodeId: 13,
    initPhoneCode: '+61',
  });

  useEffect(() => {
    const processMessage = (event) => {
      const persistingData = JSON.parse(window.sessionStorage.getItem(LIVESIGN_STORAGE_KEY));
      const data = event.data?.transactionId
        ? event.data
        : persistingData;
      if (!data) return;
      setMessage(data);
      if (data.transactionId?.toLowerCase() !== persistingData?.transactionId?.toLowerCase()) {
        window.sessionStorage.setItem(LIVESIGN_STORAGE_KEY, JSON.stringify(data));
      }
    };
    if (!window.opener) return () => { };
    window.opener.postMessage('modalInitialised', `${host}`);
    window.addEventListener('message', processMessage, false);
    return () => { window.removeEventListener('message', processMessage); };
  }, []);

  return (
    <TransactionProvider {...message}>
      <PageContent />
    </TransactionProvider>
  );
};

export default Main;
