import {
  Flex, Column, H2, P, Row,
} from '@galilee/lilee';
import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as ErroredImg } from 'images/erroredImg.svg';

const Inner = styled(Row)`
  max-width: 900px;
`;

const Errored = () => (
  <Flex alignItems="center" flexDirection="column" height="100%" justifyContent="center" width="100%">
    <Inner alignItems="center" collapseIndex={[0]} p={[7, '100px']}>
      <Column>
        <H2>Oops something went wrong</H2>
        <P fontSize={2}>
          You can now close this window and return to your document signing.
        </P>
        <P fontSize={3} fontWeight="medium">
          Start again - whatever that was
        </P>
      </Column>
      <ErroredImg />
    </Inner>
  </Flex>
);

export default Errored;
