import styled from 'styled-components/macro';
import { Text } from '@galilee/lilee';

export const SectionTitle = styled(Text)``;
SectionTitle.defaultProps = {
  fontSize: 2,
  fontWeight: 'bold',
  mb: 3,
  lineHeight: 'compact',
  color: 'primary',
};

export const SectionText = styled(Text)``;
SectionText.defaultProps = {
  fontSize: '18px',
  fontWeight: 'light',
  mb: 5,
};

export const SectionSubtitle = styled(Text)``;
SectionSubtitle.defaultProps = {
  fontSize: [4, null, null, 5],
  fontWeight: 'bold',
  lineHeight: 'compact',
  mb: [6],
};
