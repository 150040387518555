/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components/macro';
import {
  H1, Flex, Row, H3, OL, LI,
} from '@galilee/lilee';

const Inner = styled(Row)`
  max-width: 750px;
`;

const NI = styled(LI)``;

const NL = styled(OL)`
  counter-reset: item;

  > ${NI}{
    display: block;
    position: relative;
    margin-bottom: ${(p) => p.theme.space[5]};

    :before {
      content: counters(item, ".")".";
      counter-increment: item;
      position: absolute;
      margin-right: 100%;
      right: 10px; /* space between number and text */
    }
  }
`;

const Terms = () => (
  <Flex alignItems="center" flexDirection="column" justifyContent="center" p={7} width="100%">
    <Inner alignItems="center">
      <Flex flexDirection="column" maxWidth="700px" mx="auto" mb="7">
        <H1 fontSize={[5, null, null, 6]} mt="4" mb="6">Terms &amp; Conditions</H1>
        <NL>
          <NI>
            <H3 mb="6">About the Website</H3>
            <NL>
              <NI>
                www.live-sign.com (the &lsquo;Website&rsquo;) provides users (&lsquo;You&rsquo;) with the ability to;
                <OL type="a">
                  <LI>
                    Create and send, or sign, an electronic document (&lsquo;E-Document&lsquo;);
                  </LI>
                  <LI>
                    Request or complete a Verification of Identity (VOI);
                  </LI>
                  <LI>
                    The Website provides these E-Document and VOI services (the &lsquo;Services&rsquo;) by way of granting You access to the content on the Website.
                  </LI>
                </OL>
              </NI>
              <NI>
                Access to and use of the Website is provided by THELAWSTORE.COM.AU PTY LTD (ACN 653 541 729) (&lsquo;We/Us&rsquo;). Please read these terms and conditions (the &lsquo;Terms&rsquo;) carefully. By using the Website You confirm you have read, understood and agree to be bound by the Terms. If You do not agree with the Terms, You must cease using the Website immediately.
              </NI>
              <NI>
                We reserve the right to review and change any of the Terms by updating this page at our sole discretion. When We update the Terms, We will use reasonable endeavours to provide You with notice of updates to the Terms. Any changes to the Terms take immediate effect from the date of their publication, and your continued use of the Website will be deemed acceptance of the Terms.
              </NI>
            </NL>
          </NI>

          <NI>
            <H3 mb="6">No agency or warranty as to compliance</H3>
            <NL>
              <NI>
                This clause applies if You are using the Services to identify another user.
              </NI>
              <NI>
                You confirm that:
                <OL type="a">
                  <LI>
                    We are not acting as your agent and in particular we are not acting as an Identity Agent (as that term is defined in the Australian Registrars National Electronic Conveyancing Council&rsquo;s Model Participation Rules);
                  </LI>
                  <LI>
                    The Website is providing You with the Services solely to assist You to undertake Your own verification of identity of a person who is executing an E-Document;
                  </LI>
                  <LI>
                    You are responsible for satisfying any legal requirements to verify the identity of any person who signs an E-Document;
                  </LI>
                  <LI>
                    We do not warrant that the Services comply with any regulatory requirement to verify the identity of any person.
                  </LI>
                </OL>
              </NI>
              <NI>
                In order to access the Services, You may be required to register as a user of the Website. As part of the registration process, or as part of Your continued use of the Website, You may be required to provide personal information about Yourself and Your organisation, including the following information for the administrator of Your organisation and each of Your organisation&rsquo;s users:
                <OL type="a">
                  <LI>
                    Email address
                  </LI>
                  <LI>
                    Preferred username
                  </LI>
                  <LI>
                    Mailing address
                  </LI>
                  <LI>
                    Telephone number
                  </LI>
                  <LI>
                    Password
                  </LI>
                  <LI>
                    User profile/privileges
                  </LI>
                  <LI>
                    Payment details including credit/debit card
                  </LI>
                  <LI>
                    Accreditation (Solicitor, Licensed Conveyancer or employee/contractor of the Member organisation).
                  </LI>
                </OL>
              </NI>
              <NI>
                You warrant that:
                <OL type="a">
                  <LI>
                    any information you give to Us in the course of completing the registration process will always be accurate, correct and up to date. If a user registered to Your organisation ceases to be employed or authorised by Your organisation, the administrator of Your organisation&rsquo;s profile on the Website is responsible for removing such user&rsquo;s privileges and registration;
                  </LI>
                </OL>
              </NI>
            </NL>
          </NI>

          <NI>
            <H3 mb="6">Payment for the Services</H3>
            <NL>
              <NI>
                The fees payable to Us for provision of the Services (Fees) are displayed in the Website. The Website allows You to elect for the Fees to be paid either:
                <OL type="a">
                  <LI>
                    by You, by credit or debit card via the Website; or
                  </LI>
                  <LI>
                    by a third party.
                  </LI>
                </OL>
              </NI>
              <NI>
                We will provide a GST-compliant tax invoice to the person who pays the Fees, at the time of payment.
              </NI>
            </NL>
          </NI>

          <NI>
            <H3 mb="6">Your obligations</H3>
            <NL>
              <NI>
                agree to comply with the following:
                <OL type="a">
                  <LI>
                    You will use the Services only for the purposes that permitted by the Terms or any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions;
                  </LI>
                  <LI>
                    You will review each completed E-Document or VOI to confirm its accuracy;
                  </LI>
                  <LI>
                    You will immediately notify Us if you become aware of any inaccuracy;
                  </LI>
                  <LI>
                    access and use of the Website is limited, non-transferable and allows for the sole use of the Website by You for the purposes of Us providing the Services;
                  </LI>
                  <LI>
                    You will not use the Services or the Website for any illegal and/or unauthorised use which includes collecting email addresses of Members by electronic or other means for the purpose of sending unsolicited email or unauthorised framing of or linking to the Website;
                  </LI>
                  <LI>
                    You acknowledge and agree that any automated use of the Website or its Services is prohibited.
                  </LI>
                </OL>
              </NI>
            </NL>
          </NI>

          <NI>
            <H3 mb="6">Security</H3>

            <OL type="a">
              <LI>
                The Website may use a variety of security measures to protect and identify user, including access codes.
              </LI>
              <LI>
                No data transmission over the internet can be guaranteed as totally secure. Whilst we strive to protect user information, we do not warrant and cannot ensure the security of any information transmitted using the Website.
              </LI>
              <LI>
                You must ensure your access credentials remain secure. You must not share credentials (such as an access code) with any other person, or allow any other person to impersonate You.
              </LI>
              <LI>
                Whilst we may implement multi-factor authentication, it does not guarantee against unauthorised use.
              </LI>
              <LI>
                You must take Your own precautions to ensure Your use of the Website does not expose You to the risk of viruses, malicious code or other forms of interference which may damage Your property. We do not accept responsibility for any interference or damage to property which arises in connection with use of the Services.
              </LI>
              <LI>
                You must notify us immediately by sending an email to contact@live-sign.com if You no longer have access to the telephone number linked to Your account (eg if your telephone number changes); or You suspect or have reason to believe there has been or might be any unauthorised or fraudulent use of your account or any other breach of security.
              </LI>
            </OL>

          </NI>

          <NI>
            <H3 mb="6">Copyright and Intellectual Property</H3>
            <NL>
              <NI>
                The Website, the Services and all of Our related products are subject to copyright. The material on the Website is protected by copyright under the laws of Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in the site content and compilation of the website (including text, graphics, logos, button icons, video images, audio clips and software) (the &apos;Content&apos;) are owned or controlled for these purposes, and are reserved by Us, our contributors or associated entities.
              </NI>
              <NI>
                We retain all rights, title and interest in and to the Website and all related Content. Nothing you do on or in relation to the Website will transfer to you:
                <OL type="a">
                  <LI>
                    Our business name, trading name, domain name, trade mark, industrial design, patent, registered design or copyright; or
                  </LI>
                  <LI>
                    the right to use or exploit a business name, trading name, domain name, trade mark or industrial design; or

                  </LI>
                  <LI>
                    a system or process that is the subject of a patent, registered design or copyright (or an adaptation or modification of such a system or process).
                  </LI>
                </OL>
              </NI>
              <NI>
                You may not, without Our prior written permission and the permission of any other relevant rights owners: broadcast, republish, up- load to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Content or third party content for any purpose. This prohibition does not extend to materials on the Website, which are freely available for re-use or are in the public domain.
              </NI>
            </NL>
          </NI>

          <NI>
            <H3 mb="6">Privacy</H3>
            <NL>
              <NI>
                We take your privacy seriously and any information provided through your use of the Website and/or the Services are subject to Our Privacy Policy, which is available on the Website.
              </NI>
            </NL>
          </NI>

          <NI>
            <H3 mb="6">Limitation of Liability</H3>
            <NL>
              <NI>
                You acknowledge and agree that:
                <OL type="a">
                  <LI>
                    the Services and the Website are provided on an &apos; as is&apos; and &apos; as available&apos; basis;
                  </LI>
                  <LI>
                    the Services and Website cannot be guaranteed to be error-free, and the existence of any such errors will not constitute a breach of these Terms; and
                  </LI>
                  <LI>
                    You use the Services and Website at Your own risk.
                  </LI>
                </OL>
              </NI>
              <NI>
                Except as expressly provided to the contrary in these Terms to the full extent permitted by applicable law, we will not be liable for any loss, including special, indirect or consequential damages, or claim, arising directly or indirectly from:
                <OL type="a">
                  <LI>
                    the supply of a defective Service;
                  </LI>
                  <LI>
                    a failure to provide the Service or any part thereof;
                  </LI>
                  <LI>
                    errors or interruptions occurring in the course of using, or as part of, the Service or Website;
                  </LI>
                  <LI>
                    corruptions to or loss of data in connection with the Services;
                  </LI>
                  <LI>
                    any suspension or discontinuance of the Service or Website;
                  </LI>
                  <LI>
                    a breach of these Terms.
                  </LI>
                </OL>
              </NI>
              <NI>
                You may have rights under the Australian Consumer Law, including the Consumer Guarantees, which cannot lawfully be excluded and nothing in these Terms has the effect of excluding, restricting or modifying such rights and Consumer Guarantees.Where the law implies a warranty into these Terms of Use which may not lawfully be excluded (in particular warranties under Australian Consumer Law) our liability for breach of such a warranty will be limited to, if applicable, either supplying the services again or payment of the cost of having the services supplied again.
              </NI>
              <NI>
                You acknowledge that You have exercised Your independent judgment in acquiring the Services and have not relied on any representation that we have made which has not been stated expressly in these Terms or upon descriptions or illustrations or specifications contained in any document including catalogues or publicity material that we have produced.

              </NI>
              <NI>
                You will indemnify us and hold us harmless fully against all liabilities, costs, losses, claims and expenses which we may incur to a third party as a result of Your breach of any of the provisions of these Terms.
              </NI>
              <NI>
                We are not responsible or liable in any manner for any site content (including the Content and any third party Content) posted on the Website or in connection with the Services.
              </NI>
            </NL>
          </NI>

          <NI>
            <H3 mb="6">Termination of Contract</H3>
            <NL>
              <NI>
                The Terms will continue to apply until terminated by either you or by Us as set out below.
              </NI>
              <NI>
                If you want to terminate your membership of the Website, you may do so by:
                <OL type="a">
                  <LI>
                    notifying Us at any time; and
                  </LI>
                  <LI>
                    closing your accounts for all Services which you use, where We have made this option available to you.
                  </LI>
                </OL>
                Your notice should be sent, in writing, to Us via the &apos;Contact Us&apos; link on our homepage.
              </NI>
              <NI>
                We may at any time, terminate your membership of the Website if:
                <OL>
                  <LI>
                    you have breached any provision of the Terms or intend to breach any provision;
                  </LI>
                  <LI>
                    We are required to do so by law; or
                  </LI>
                  <LI>
                    the provision of the Services no longer commercially viable in our opinion.
                  </LI>
                </OL>
              </NI>
              <NI>
                Subject to local applicable laws, We reserve the right to discontinue or cancel your membership to the Website at any time and may suspend or deny, in our sole discretion, your access to all or any portion of the Website or the Services without notice if you breach any provision of the Terms or any applicable law or if your conduct impacts Our name or reputation or violates the rights of those of another party.
              </NI>
              <NI>
                When the Terms come to an end, all of the legal rights, obligations and liabilities that you and Us have benefited from, been subject to (or which have accrued over time whilst the Terms have been in force) or which are expressed to continue indefinitely, shall be unaffected by this cessation, and the provisions of this clause shall continue to apply to such rights, obligations and liabilities indefinitely.
              </NI>
            </NL>
          </NI>

          <NI>
            <H3 mb="6">Indemnity</H3>
            <NL>
              <NI>
                You agree to indemnify Us, Our affiliates, employees, agents, contributors, third party content providers and licensors from and against:
                <OL type="a">
                  <LI>
                    all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with any Services you consume through the Website;
                  </LI>
                  <LI>
                    any direct or indirect consequences of you accessing, using or transacting on the Website or attempts to do so and any breach by you or your agents of these Terms; and/or
                  </LI>
                  <LI>
                    any breach of the Terms.
                  </LI>
                </OL>
              </NI>
            </NL>
          </NI>

          <NI>
            <H3 mb="6">Dispute Resolution</H3>
            <NL>
              <NI>
                If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought).
              </NI>
              <NI>
                A party to the Terms claiming a dispute (&apos;Dispute&apos;) has arisen under the Terms, must give written notice to the other party detailing the nature of the dispute, the desired outcome and the action required to settle the Dispute.
              </NI>
              <NI>
                On receipt of that notice (&apos;Notice&apos;) by that other party, the parties to the Terms (&apos;Parties&apos;) must:
                <OL type="a">
                  <LI>
                    Within 14 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree;
                  </LI>
                  <LI>
                    If for any reason whatsoever, 14 days after the date of the Notice, the Dispute has not been resolved, the Parties must either agree upon selection of a mediator or request that an appropriate mediator be appointed by the President of the Australian Mediation Association or his or her nominee;
                  </LI>
                  <LI>
                    The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a pre-condition to the mediation commencing. The Parties must each pay their own costs associated with the mediation;
                  </LI>
                  <LI>
                    The mediation will be held in NSW, Australia.
                  </LI>
                </OL>
              </NI>
              <NI>
                Confidential:
                <br />
                All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as &quot;without prejudice&quot; negotiations for the purpose of applicable laws of evidence.
              </NI>
              <NI>
                Termination of Mediation:
                <br />
                If 3 months have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the mediator must do so.
              </NI>
            </NL>
          </NI>

          <NI>
            <H3 mb="6">General</H3>
            <NL>
              <NI>
                Venue and jurisdiction
                <br />
                The Website and the Services are intended to be provided to residents of Australia only. In the event of any dispute arising out of or in relation to the Website, you agree that the exclusive venue for resolving any dispute shall be in the courts of New South Wales, Australia.
              </NI>
              <NI>
                Governing Law:
                <br />
                The Terms are governed by the laws of New South Wales, Australia. Any dispute, controversy, proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted and construed by, under and pursuant to the laws of New South Wales, Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns.
              </NI>
              <NI>
                Independent Legal Advice::
                <br />
                Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both parties having taken the opportunity to obtain independent legal advice and declare the Terms are not against public policy on the grounds of inequality or bargaining power or general grounds of restraint of trade.
              </NI>
              <NI>
                Severance
                <br />
                If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed and the rest of the Terms shall remain in force.
              </NI>
            </NL>
          </NI>
        </NL>
      </Flex>
    </Inner>
  </Flex>
);

export default Terms;
